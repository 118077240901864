import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Calendar from './Calendar';
import {
  fetchUserSelf, fetchUserReservationRequests, fetchUserReservations, updateCalendarFilters,
} from '../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  reservations: state.reservations,
  reservationRequests: state.reservationRequests,
  filters: state.calendarFilters,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUserSelf,
  fetchUserReservationRequests,
  fetchUserReservations,
  updateCalendarFilters,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Calendar);
