export const SUPPLIER_FETCH = 'supplier/FETCH_ONE';
export const SUPPLIERS_FETCH = 'supplier/FETCH_MULTIPLE';
export const FETCH_TIMESLOTS = 'service/FETCH_TIMESLOTS';
export const SUPPLIER_ACCEPT_RESERVATION_REQUEST = 'supplier/ACCEPT_RESERVATION_REQUEST';
export const SUPPLIER_REFUSE_RESERVATION_REQUEST = 'supplier/REFUSE_RESERVATION_REQUEST';
export const SUPPLIERS_FETCH_BY_NAME = 'supplier/FETCH_BY_NAME';
export const SUPPLIER_SEND_DOCUMENTS = 'supplier/SEND_DOCUMENTS';
export const SUPPLIER_UPDATE_TIMETABLE = 'supplier/UPDATE_TIMETABLE';
export const SUPPLIER_UPDATE_MAX_DISTANCE = 'supplier/UPDATE_MAX_DISTANCE';
export const SUPPLIER_ADD_CONTACT = 'supplier/add_contact';
export const SUPPLIER_DELETE_CONTACT = 'supplier/delete_contact';

export const fetchSupplier = (supplierId) => ({
  type: SUPPLIER_FETCH,
  payload: {
    supplier_id: supplierId,
  },
});

export const fetchTimeSlots = (payload) => ({
  type: FETCH_TIMESLOTS,
  payload,
});

export const fetchSuppliersByName = (payload) => ({
  type: SUPPLIERS_FETCH_BY_NAME,
  payload,
});

export const userAcceptReservationRequest = (payload) => ({
  type: SUPPLIER_ACCEPT_RESERVATION_REQUEST,
  payload,
});

export const userRefuseReservationRequest = (payload) => ({
  type: SUPPLIER_REFUSE_RESERVATION_REQUEST,
  payload,
});

export const sendDocuments = (payload) => ({
  type: SUPPLIER_SEND_DOCUMENTS,
  payload,
});

export const updateTimetable = (payload) => ({
  type: SUPPLIER_UPDATE_TIMETABLE,
  payload,
});


export const updateMaxDistance = (payload) => ({
  type: SUPPLIER_UPDATE_MAX_DISTANCE,
  payload,
});

export const addSupplierContact = (payload) => ({
  type: SUPPLIER_ADD_CONTACT,
  payload,
});

export const deleteSupplierContact = (payload) => ({
  type: SUPPLIER_DELETE_CONTACT,
  payload,
});
