/* eslint-disable import/prefer-default-export */
import Swal from 'sweetalert2';
import {
  Api,
  USER_LOGIN,
  USER_FETCH_SELF,
  USER_FETCH_RESERVATION_REQUESTS,
  USER_FETCH_RESERVATIONS,
  FETCH_GENDERS,
  USER_REGISTER,
  FETCH_NEEDY,
  FETCH_ADDRESSES,
  FETCH_PAYMENT_METHODS,
  ADD_NEEDY,
  USER_LOGOUT,
  ADD_ADDRESSES,
  fetchAddresses,
  SEND_RESERVATION_REQUEST,
  SEND_GUEST_RESERVATION_REQUEST,
  navigateTo,
  fetchUserReservationRequests,
  CANCEL_RESERVATION_SUPPLIER,
  CANCEL_RESERVATION_REQUEST_USER,
  USER_UPDATE_DATA,
  fetchUserSelf,
  USER_UPDATE_PROFILE_PHOTO,
  DELETE_ADDRESSES,
  UPDATE_NEEDY,
  DELETE_NEEDY,
  USER_UPDATE_COVER_PHOTO,
  SUPPLIER_ACCEPT_RESERVATION_REQUEST,
  SUPPLIER_REFUSE_RESERVATION_REQUEST,
  CANCEL_RESERVATION_USER,
  fetchUserReservations,
  USER_UPDATE_SERVICE,
  fetchUserServices,
  USER_ADD_SERVICE,
  SERVICES_DELETE,
  ADD_PAYMENT_METHODS,
  fetchPaymentMethods,
  DELETE_PAYMENT_METHODS,
  USER_RESET_PASSWORD,
  USER_SEND_NEW_PASSWORD,
  USER_DOWNLOAD_INVOICE,
  baseURL, SUPPLIER_SEND_DOCUMENTS,
} from '../actions';
import { handleResultActions } from '../../libs/utils';

const api = new Api();

export const usersMiddleware = (store) => (next) => (action) => {
  const middlewareAction = {};

  middlewareAction['persist/REHYDRATE'] = async () => {
    const s = store.getState();
    if (s.user && s.user.access_token) {
      await api.setAccessToken(s.user.access_token);
    }

    store.dispatch({
      type: 'MAIN_LOADING_FINISHED',
    });
  };

  middlewareAction[USER_LOGIN] = async () => {
    const { payload, onSuccess, onError } = action;
    const respAction = await api.call({
      url: '/login',
      method: 'post',
      data: {
        ...payload,
      },
      actionType: action.type,
    });

    if (respAction.type === `${USER_LOGIN}_SUCCESS`) {
      api.setAccessToken(respAction.payload.data.access_token);

      if (onSuccess) {
        onSuccess();
      }
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[USER_FETCH_SELF] = async () => {
    const respAction = await api.call({
      url: '/me',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[USER_FETCH_RESERVATION_REQUESTS] = async () => {
    const respAction = await api.call({
      url: '/me/reservation-requests',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[USER_FETCH_RESERVATIONS] = async () => {
    const respAction = await api.call({
      url: '/me/reservations',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[FETCH_GENDERS] = async () => {
    const respAction = await api.call({
      url: '/genders',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[USER_REGISTER] = async () => {
    const { payload: data, onSuccess } = action;
    const respAction = await api.call({
      url: '/register',
      method: 'post',
      data,
      actionType: action.type,
    });

    if (respAction.type === `${USER_REGISTER}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
    }

    store.dispatch(respAction);
  };

  middlewareAction[FETCH_NEEDY] = async () => {
    const respAction = await api.call({
      url: '/me/needy',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[FETCH_ADDRESSES] = async () => {
    const respAction = await api.call({
      url: '/me/addresses',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[FETCH_PAYMENT_METHODS] = async () => {
    const respAction = await api.call({
      url: '/me/payment-methods',
      method: 'get',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[ADD_NEEDY] = async () => {
    const { payload: data } = action;
    const respAction = await api.call({
      url: '/me/needy',
      method: 'post',
      data,
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${ADD_NEEDY}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    handleResultActions(action, true);
    Swal.fire('Ok!', 'Anziano aggiunto con successo.', 'success').then(()=>{
      window.location.reload();      
    });

  };

  middlewareAction[ADD_ADDRESSES] = async () => {
    const { payload: data } = action;
    const respAction = await api.call({
      url: '/me/addresses',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${ADD_ADDRESSES}_SUCCESS`] = async () => {
    store.dispatch(fetchAddresses());
    handleResultActions(action, true);
  };

  middlewareAction[DELETE_ADDRESSES] = async () => {
    const { payload: data } = action;
    const respAction = await api.call({
      url: '/me/addresses',
      method: 'delete',
      data,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${DELETE_ADDRESSES}_SUCCESS`] = async () => {
    store.dispatch(fetchAddresses());
    handleResultActions(action);
  };

  middlewareAction[SEND_GUEST_RESERVATION_REQUEST] = async () => {
    const state = store.getState();
    const {
      search,
      supplier,
    } = state;

    const data = {
      first_name: search.first_name,
      last_name: search.last_name,
      email: search.email,
      phone: search.phone,
      address: search.address,
      service_id: search.service_id,
      supplier_id: supplier.id,
      date: search.date,
      timeslots: search.timeslots,
    };

    const respAction = await api.call({
      url: '/reservation-requests',
      method: 'post',
      data,
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[SEND_RESERVATION_REQUEST] = async () => {
    const state = store.getState();
    const {
      reservation, search, supplier,
    } = state;

    const data = {
      ...search,
      ...reservation,
      supplier_id: supplier.id,
    };

    const respAction = await api.call({
      url: '/reservation-requests',
      method: 'post',
      data,
      actionType: action.type,
    });

    respAction.history = action.history;

    store.dispatch(respAction);
  };

  middlewareAction[`${SEND_RESERVATION_REQUEST}_SUCCESS`] = async () => {
    action.history.push('/dashboard/prenostazioni');
    store.dispatch(fetchUserReservationRequests());
    Swal.fire('Grazie!', 'Richiesta inviata con successo.', 'success');
  };

  // middlewareAction[`${SEND_RESERVATION_REQUEST}_SUCCESS`] = async () => {
  //   store.dispatch(showToast('Prenotazione avvenuta con successo.\nIl fornitore dovrà elaborare la tua richiesta.'));
  //   store.dispatch(fetchUserReservationRequests());
  //   store.dispatch(fetchUserReservations());
  //   store.dispatch(navigateTo({
  //     route: 'Agenda',
  //   }));
  // };

  middlewareAction[CANCEL_RESERVATION_SUPPLIER] = async () => {
    const respAction = await api.call({
      url: `/reservation-requests/${action.payload.reservationId}`,
      method: 'delete',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[CANCEL_RESERVATION_REQUEST_USER] = async () => {
    const respAction = await api.call({
      url: `/reservation-requests/${action.payload.reservationRequestId}`,
      method: 'delete',
      actionType: action.type,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${CANCEL_RESERVATION_REQUEST_USER}_SUCCESS`] = async () => {
    store.dispatch(navigateTo({
      route: 'Agenda',
    }));
  };

  middlewareAction[USER_LOGOUT] = async () => {
    const { onSuccess } = action;
    await api.reset();
    onSuccess();
  };

  middlewareAction[USER_UPDATE_DATA] = async () => {
    const respAction = await api.call({
      url: '/me',
      method: 'post',
      data: action.payload,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${USER_UPDATE_DATA}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    handleResultActions(action, true);
  };

  middlewareAction[`${USER_UPDATE_DATA}_FAIL`] = async () => {
    const { payload } = action;
    Swal.fire('Errore', payload.data.error, 'error');
  };

  middlewareAction[USER_UPDATE_PROFILE_PHOTO] = async () => {
    const respAction = await api.call({
      url: '/me',
      method: 'post',
      data: action.payload,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${USER_UPDATE_DATA}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    handleResultActions(action, true);
  };

  middlewareAction[USER_UPDATE_PROFILE_PHOTO] = async () => {
    const data = new FormData();
    data.append('profile', action.payload);

    const respAction = await api.call({
      url: '/me',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${USER_UPDATE_PROFILE_PHOTO}_SUCCESS`] = async () => {
    Swal.fire('Ok!', 'Foto profilo caricata con successo.', 'success');
    store.dispatch(fetchUserSelf());
    handleResultActions(action, true);
  };

  middlewareAction[`${USER_UPDATE_PROFILE_PHOTO}_FAIL`] = async () => {
    Swal.fire('Errore!', 'Errore durante il caricamento della foto del profilo', 'error');
  };

  middlewareAction[USER_UPDATE_COVER_PHOTO] = async () => {
    const data = new FormData();
    data.append('cover', action.payload);

    const respAction = await api.call({
      url: '/me',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${USER_UPDATE_COVER_PHOTO}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    Swal.fire('Ok!', 'Foto di copertina caricata con successo.', 'success');
    handleResultActions(action, true);
  };

  middlewareAction[UPDATE_NEEDY] = async () => {
    const respAction = await api.call({
      url: '/me/needy',
      method: 'put',
      data: action.payload,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${UPDATE_NEEDY}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    handleResultActions(action, true);
  };

  middlewareAction[DELETE_NEEDY] = async () => {
    const respAction = await api.call({
      url: `/me/needy/${action.payload.id}`,
      method: 'delete',
      data: action.payload,
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${DELETE_NEEDY}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
    handleResultActions(action, true);
  };

  middlewareAction[SUPPLIER_ACCEPT_RESERVATION_REQUEST] = async () => {
    const respAction = await api.call({
      url: `/reservation-requests/${action.payload}/status`,
      method: 'post',
      data: {
        status: 'accepted',
      },
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_ACCEPT_RESERVATION_REQUEST}_SUCCESS`] = async () => {
    store.dispatch(fetchUserReservationRequests());
    handleResultActions(action, true);
  };

  middlewareAction[SUPPLIER_REFUSE_RESERVATION_REQUEST] = async () => {
    const respAction = await api.call({
      url: `/reservation-requests/${action.payload}/status`,
      method: 'post',
      data: {
        status: 'refused',
      },
      actionType: action.type,
      action,

    });

    store.dispatch(respAction);
  };

  middlewareAction[`${SUPPLIER_REFUSE_RESERVATION_REQUEST}_SUCCESS`] = async () => {
    store.dispatch(fetchUserReservationRequests());
    handleResultActions(action, true);
  };

  middlewareAction[CANCEL_RESERVATION_USER] = async () => {
    const respAction = await api.call({
      url: `/reservations/${action.payload}/status`,
      method: 'post',
      data: {
        status: 'canceled',
      },
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${CANCEL_RESERVATION_USER}_SUCCESS`] = async () => {
    store.dispatch(fetchUserReservations());
    handleResultActions(action, true);
  };

  middlewareAction[USER_UPDATE_SERVICE] = async () => {
    const { payload: data, onSuccess, onError } = action;

    const respAction = await api.call({
      url: '/me/services',
      method: 'post',
      data: {
        ...data,
        updating: true,
      },
      actionType: action.type,
      action,
    });

    if (respAction.type === `${USER_UPDATE_SERVICE}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
    } else {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[`${USER_UPDATE_SERVICE}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
  };

  middlewareAction[USER_ADD_SERVICE] = async () => {
    const { payload: data, onSuccess, onError } = action;

    const respAction = await api.call({
      url: '/me/services',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    if (respAction.type === `${USER_ADD_SERVICE}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[`${USER_ADD_SERVICE}_SUCCESS`] = async () => {
    store.dispatch(fetchUserSelf());
  };

  middlewareAction[`${USER_ADD_SERVICE}_FAIL`] = async () => {
    const { payload } = action;
    Swal.fire('Errore', payload.data.message, 'error');
  };

  middlewareAction[SERVICES_DELETE] = async () => {
    const { payload: data, onSuccess, onError } = action;
    const respAction = await api.call({
      url: '/me/services',
      method: 'delete',
      data,
      actionType: action.type,
      action,
    });

    if (respAction.type === `${SERVICES_DELETE}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
      store.dispatch(fetchUserSelf());
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[ADD_PAYMENT_METHODS] = async () => {
    const { payload: data, onSuccess, onError } = action;

    const respAction = await api.call({
      url: '/me/payment-methods',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    if (respAction.type === `${ADD_PAYMENT_METHODS}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
      store.dispatch(fetchUserSelf());
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[`${ADD_PAYMENT_METHODS}_SUCCESS`] = async () => {
    store.dispatch(fetchPaymentMethods());
  };

  middlewareAction[DELETE_PAYMENT_METHODS] = async () => {
    const { payload } = action;

    const respAction = await api.call({
      url: '/me/payment-methods',
      method: 'delete',
      data: {
        payment_method_id: payload,
      },
      actionType: action.type,
      action,
    });

    store.dispatch(respAction);
  };

  middlewareAction[`${DELETE_PAYMENT_METHODS}_SUCCESS`] = async () => {
    store.dispatch(fetchPaymentMethods());
  };

  middlewareAction[USER_RESET_PASSWORD] = async () => {
    const { payload: data, onSuccess, onError } = action;

    const respAction = await api.call({
      url: '/reset-request',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    if (respAction.type === `${USER_RESET_PASSWORD}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[USER_SEND_NEW_PASSWORD] = async () => {
    const { payload: data, onSuccess, onError } = action;

    const respAction = await api.call({
      url: '/reset',
      method: 'post',
      data,
      actionType: action.type,
      action,
    });

    if (respAction.type === `${USER_SEND_NEW_PASSWORD}_SUCCESS` && onSuccess !== undefined) {
      onSuccess();
    } else if (onError) {
      onError();
    }

    store.dispatch(respAction);
  };

  middlewareAction[USER_DOWNLOAD_INVOICE] = async () => {
    const { payload } = action;
    window.open(`${baseURL}/reservation-requests/${payload}/invoice?token=${api.api.defaults.headers.common.Authorization.replace('Bearer ', '')}`, '_blank');
  };

  next(action);

  if (middlewareAction[action.type]) { middlewareAction[action.type](); }
};
