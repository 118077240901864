import { reducerFromMap } from './commons.reducers';
import { FLUSH_SEARCH_CONFIG, ADD_SEARCH_OPTION, PERFORM_SEARCH } from '../actions';

const reducerMap = {};

reducerMap[FLUSH_SEARCH_CONFIG] = (state) => ({
  ...state,
  search: undefined,
});

reducerMap[ADD_SEARCH_OPTION] = (state, action) => ({
  ...state,
  search: {
    ...state.search,
    [action.payload.key]: action.payload.data,
  },
});

reducerMap[PERFORM_SEARCH] = (state) => ({
  ...state,
  loading: true,
  searchResults: undefined,
});

reducerMap[`${PERFORM_SEARCH}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  searchResults: action.payload.data.data,
});

reducerMap[`${PERFORM_SEARCH}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
});

const reducer = reducerFromMap(reducerMap);

export default reducer;
