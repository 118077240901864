import React, { useEffect, useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { Grid, Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import DashboardMenu from '../../components/dashboard-menu';

import Calendar from './calendar';
import DashboardContent from './dashboard-content';
import Settings from './settings';
import ReservationRequests from './reservation-requests';
import Reservations from './reservations';
import Supplier from './supplier';

import './styles.css';


const Dashboard = ({ history, user, fetchUserSelf }) => {
  const [activeItem, setActiveItem] = useState();


  useEffect(() => {
    if (user) {
      fetchUserSelf();

      const items = history.location.pathname.split('/');

      if (items.length > 2) {
        setActiveItem(items[2]);
      } else {
        setActiveItem(items[1]);
      }
    } else {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (user === undefined) {
      history.push('/');
    }
  }, [user]);

  useEffect(() => {
    const items = history.location.pathname.split('/');
    if (items.length > 2) {
      setActiveItem(items[2]);
    } else {
      setActiveItem(items[1]);
    }
  }, [history.location]);

  const renderContent = useCallback(() => {
    if (activeItem === 'calendario') return <Calendar />;
    if (activeItem === 'preferenze') return <Settings />;
    if (activeItem === 'richieste') return <ReservationRequests />;
    if (activeItem === 'prestazioni') return <Reservations />;
    if (activeItem === 'fornitore') return <Supplier />;

    return <DashboardContent />;
  }, [activeItem]);

  return (
    <div className="search-wrapper">
      <Container className="categories-explore">
        <Grid container>
        <Grid items={true} className="dashboard-menu-wrapper" xs={12} md={4}>
            <DashboardMenu activeItem={activeItem} />
          </Grid>
          <Grid  items={true} xs={12} md={8}>
            {renderContent()}
          </Grid>
        </Grid>

      </Container>
    </div>
  );
};
export default withRouter(Dashboard);
