import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddServiceModal from './AddServiceModal';

import {
  addUserService,
  deleteServices,
  updateUserService,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  services: state.services,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addUserService,
  deleteServices,
  updateUserService,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddServiceModal);
