/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Swal from 'sweetalert2';
import Loader from '../../../../components/loader';
import AddContactModal from '../../../../components/add-contact-modal';

import colors from '../../../../res/colors';
import { getFullAddress } from '../../../../libs/utils';

const SupplierContacts = ({ user, deleteSupplierContact }) => {
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contact, setContact] = useState();

  useEffect(() => {
    if (
      user !== undefined
      || user.data !== undefined
      || user.data.supplier_data !== undefined
    ) {
      setContacts(user.data.supplier_data.info.contacts);
    } else {
      setContacts([]);
    }
  }, [user]);

  useEffect(() => {
    if (!showModal) {
      setContact(undefined);
    }
  }, [setShowModal]);

  const renderModal = () => (
    <AddContactModal
      visible={showModal}
      contact={contact}
      onClose={() => setShowModal(false)}
    />
  );


  if (user === undefined) return <Loader />;

  const handleDelete = (c) => {
    Swal.fire({
      title: 'Sei sicuro di voler cancellare questo contatto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.secondary.normal,
      cancelButtonColor: colors.red.normal,
      confirmButtonText: 'Si, cancella!',
      cancelButtonText: 'Annulla',
    }).then((result) => {
      if (result.value) {
        deleteSupplierContact(c);
      }
    });
  };

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      {contacts.length > 0 ? contacts.map(
        (c) => (
          <div>
            <div
              onClick={() => {
                setContact(c);
                setShowModal(true);
              }}
              className="setting-item-title"
            >
              {c.type}
            </div>
            <div
              onClick={() => {
                setContact(c);
                setShowModal(true);
              }}
              className="setting-item-value"
            >
              {c.value}
            </div>
            <div className="setting-item-edit">
              <p style={{ color: '#E46304' }} onClick={() => handleDelete(c)}>
          Elimina
                <HighlightOffIcon style={{ color: '#E46304', fontSize: 16, marginLeft: 8 }}>filled</HighlightOffIcon>
              </p>
            </div>
          </div>
        ),
      ) : <p>Non hai ancora aggiunto nessun contatto</p>}

      <AddCircleIcon
        onClick={() => {
          setContact(undefined);
          setShowModal(true);
        }}
        style={{
          color: colors.secondary.normal, fontSize: 40, marginTop: 30, cursor: 'pointer',
        }}
      >
        filled
      </AddCircleIcon>

      {showModal && renderModal()}
    </div>
  );
};

export default SupplierContacts;
