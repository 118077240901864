import React, { useState, useEffect } from 'react';
import ImageUploader from 'react-images-upload';
import { Button } from 'react-bootstrap';
import StepRangeSlider from 'react-step-range-slider';


import { getMediaUrl } from '../../../../libs/utils';

import './styles.css';

const ranges = [
  { value: 5, step: 20 }, // acts as min value
  { value: 25, step: 25 },
  { value: 50, step: 950 },
  { value: 1000 }, // acts as max value
];

const MaxDistance = ({ user, updateMaxDistance }) => {
  const [maxDistance, setMaxDistance] = useState(user.data.info.max_distance !== null ? user.data.info.max_distance : undefined);

  return (
    <div>
      <p>Imposta la massima distanza (in KM) che sei disposto a percorrere per soddisfare una prenotazione</p>

      <div style={{ marginTop: 40 }}>
        <StepRangeSlider
          value={maxDistance}
          range={ranges}
          onChange={(value) => setMaxDistance(value)}
        />
      </div>


      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <Button
          style={{ marginLeft: 15 }}
          variant="yellow"
          disabled={maxDistance === undefined}
          onClick={() => {
            updateMaxDistance(maxDistance);
          }}
        >
        Salva
        </Button>
      </div>

    </div>
  );
};

export default MaxDistance;
