import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaymentsSettings from './PaymentsSettings';

import { fetchUserSelf, fetchPaymentMethods, deletePaymentMethods } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  paymentMethods: state.paymentMethods,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUserSelf,
  deletePaymentMethods,
  fetchPaymentMethods,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentsSettings);
