import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { userCancelReservation, downloadInvoice, changeReservationStatus } from '../../store/actions';

import ReservationClosing from './ReservationClosing';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  userCancelReservation,
  downloadInvoice,
  changeReservationStatus,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationClosing);
