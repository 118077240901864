import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SupplierContacts from './SupplierContacts';

import { deleteSupplierContact } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteSupplierContact,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierContacts);
