/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import AddAddressModal from './add-address-modal';
import Loader from '../../../../components/loader';


import colors from '../../../../res/colors';
import { getFullAddress } from '../../../../libs/utils';

const AddressSettings = ({
  user, addresses, addAddresses, deleteAddresses,
}) => {
  const [showModal, setShowModal] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (user.data.type === 'supplier' && addresses && addresses.length >= 1) setDisabled(true);
    else setDisabled(false);
  }, [addresses]);

  if (addresses === undefined) return <Loader />;

  return (
    <div className="settings-content-wrapper account-settings-wrapper">
      {addresses.length > 0 ? addresses.map(
        (address) => (
          <div>
            <div className="setting-item-value">{getFullAddress(address)}</div>
            <div className="setting-item-edit">
              <p style={{ color: '#E46304' }} onClick={() => deleteAddresses(address)}>
          Elimina
                <HighlightOffIcon style={{ color: '#E46304', fontSize: 16, marginLeft: 8 }}>filled</HighlightOffIcon>
              </p>
            </div>
          </div>
        ),
      ) : <p>Non hai ancora aggiunto nessun indirizzo</p>}


      <AddCircleIcon
        onClick={disabled ? () => {} : () => setShowModal(true)}
        style={{ color: disabled ? colors.grey.normal : colors.secondary.normal, fontSize: 40, marginTop: 30 }}
      >
        filled
      </AddCircleIcon>

      {disabled ? <p style={{ marginTop: 10 }}><small>Attualmente è possibile aggiungere un solo indirizzo per fornitore</small></p> : ''}

      <AddAddressModal
        visible={showModal}
        onClose={() => setShowModal(false)}
        addAddress={addAddresses}
      />
    </div>
  );
};

export default AddressSettings;
