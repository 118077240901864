import * as Yup from 'yup';
import raisinYup from '@raisin/yup-validations';

export const getSupplierValidationSchema = ({ genders }) => Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Il nome contiene pochi caratteri')
    .max(50, 'Il nome contiene troppi caratteri')
    .required('Campo obbligatorio'),
  last_name: Yup.string()
    .min(2, 'Il cognome contiene pochi caratteri')
    .max(50, 'Il cognome contiene troppi caratteri')
    .required('Campo obbligatorio'),
  email: Yup.string()
    .email('L\'email non è valida')
    .required('Campo obbligatorio'),
  birthday: Yup.string()
    .required('Campo obbligatorio'),
  gender: Yup.string()
    .oneOf(genders ? genders.map((g) => g.name) : [], 'Sesso non valido')
    .required('Campo obbligatorio'),
  mobile_number: Yup.string()
    .min(9, 'Numero non valido')
    .max(12, 'Numero non valido')
    .required('Campo obbligatorio'),
  phone_number: Yup.string()
    .min(9, 'Numero non valido')
    .max(12, 'Numero non valido'),
  cod_fisc_supplier: Yup.string()
    .test('len', 'Campo obbligatorio', (val) => {
      if (val) {
        const valid = val.match(/[a-zA-Z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[a-zA-Z][0-9lmnpqrstuvLMNPQRSTUV]{2}[a-zA-Z][0-9lmnpqrstuvLMNPQRSTUV]{3}[a-zA-Z]/);
        return valid || (/^\d+$/.test(val) && val.length === 11);
      }
      return false;
    })
    .required('Campo obbligatorio'),
  cod_fisc: Yup.string()
    .matches(/[a-zA-Z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[a-zA-Z][0-9lmnpqrstuvLMNPQRSTUV]{2}[a-zA-Z][0-9lmnpqrstuvLMNPQRSTUV]{3}[a-zA-Z]/, {
      excludeEmptyString: true,
      message: 'Il codice fiscale non è valido',
    })
    .required('Campo obbligatorio'),
  address: Yup.string()
    .required('Campo obbligatorio'),
  password: Yup.string()
    .min(8, 'Minimo 8 caratteri')
    .required('Campo obbligatorio')
    .test(
      "regex",
      "La password deve contenere almeno 8 caratteri e contenere 1 carattere speciale, 1 maiuscolo, 1 numero e 1 minuscola",
      val => {
        let regExp = new RegExp(
          "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
        );
        return regExp.test(val);
      }
    ),
  password_confirm: Yup.string()
  .min(8, 'Minimo 8 caratteri')
  .required('Campo obbligatorio')
  .test(
    "regex",
    "La password deve contenere almeno 8 caratteri e contenere 1 carattere speciale, 1 maiuscolo, 1 numero e 1 minuscola",
    val => {
      let regExp = new RegExp(
        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
      );
      return regExp.test(val);
    }
  ).test('checkPasswordConfirmation', 'Non è uguale alla password inserita', function (value) {
      const password = this.resolve(Yup.ref('password'));
      return password === value;
    }),
  email_confirm: Yup.string()
    .test('checkEmailConfirmation', 'Non è uguale alla email inserita', function (value) {
      const email = this.resolve(Yup.ref('email'));
      return email === value;
    })
    .required('Campo obbligatorio'),
  rag_soc: Yup.string()
    .min(2, 'Contiene pochi caratteri')
    .max(100, 'Contiene troppi caratteri')
    .required('Campo obbligatorio'),
  piva: Yup.string()
    .test('len', 'Formato non valido', (val) => val !== undefined && /^\d+$/.test(val) && val.length === 11)
    .required('Campo obbligatorio'),
  privacy: Yup.boolean().oneOf([true], 'Devi accettre le condizioni contrattuali dei fornitori per proseguire con la registrazione').required(),
  //iban: raisinYup.string().iban('IBAN non valido').required('Campo obbligatorio'),
  iban: raisinYup.string(),
  profile: Yup.mixed().test('fileSize', 'File non valido', (value) => {
    if (typeof value === 'string') return false;
    if (value === undefined || !value.length) return true;
    return value[0].size <= 2000000;
  }),
});

export const getUserValidationSchema = ({ genders }) => Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Il nome contiene pochi caratteri')
    .max(50, 'Il nome contiene troppi caratteri')
    .required('Campo obbligatorio'),
  last_name: Yup.string()
    .min(2, 'Il cognome contiene pochi caratteri')
    .max(50, 'Il cognome contiene troppi caratteri')
    .required('Campo obbligatorio'),
  email: Yup.string()
    .email('L\'email non è valida')
    .required('Campo obbligatorio'),
  birthday: Yup.string()
    .required('Campo obbligatorio'),
  gender: Yup.string()
    .oneOf(genders ? genders.map((g) => g.name) : [], 'Sesso non valido')
    .required('Campo obbligatorio'),
  mobile_number: Yup.string()
    .min(9, 'Numero non valido')
    .max(12, 'Numero non valido')
    .required('Campo obbligatorio'),
  phone_number: Yup.string()
    .min(9, 'Numero non valido')
    .max(12, 'Numero non valido'),
  cod_fisc: Yup.string().matches(/[a-zA-Z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[a-zA-Z][0-9lmnpqrstuvLMNPQRSTUV]{2}[a-zA-Z][0-9lmnpqrstuvLMNPQRSTUV]{3}[a-zA-Z]/, {
      excludeEmptyString: true,
      message: 'Il codice fiscale non è valido',
    })
    .required('Campo obbligatorio'),
  address: Yup.string()
    .min(8, 'Minimo 8 caratteri')
    .required('Campo obbligatorio'),
    password: Yup.string()
    .min(8, 'Minimo 8 caratteri')
    .required('Campo obbligatorio')
    .test(
      "regex",
      "La password deve contenere almeno 8 caratteri e contenere 1 carattere speciale, 1 maiuscolo, 1 numero e 1 minuscola",
      val => {
        let regExp = new RegExp(
          "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
        );
        return regExp.test(val);
      }
    ),
  password_confirm: Yup.string()
  .min(8, 'Minimo 8 caratteri')
  .required('Campo obbligatorio')
  .test(
    "regex",
    "La password deve contenere almeno 8 caratteri e contenere 1 carattere speciale, 1 maiuscolo, 1 numero e 1 minuscola",
    val => {
      let regExp = new RegExp(
        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
      );
      return regExp.test(val);
    }
  ).test('checkPasswordConfirmation', 'Non è uguale alla password inserita', function (value) {
      const password = this.resolve(Yup.ref('password'));
      return password === value;
    }),
  email_confirm: Yup.string()
    .test('checkEmailConfirmation', 'Non è uguale alla email inserita', function (value) {
      const email = this.resolve(Yup.ref('email'));
      return email === value;
    })
    .required('Campo obbligatorio'),
  privacy: Yup.boolean().oneOf([true], 'Devi accettre la privacy per proseguire con la registrazione').required(),
  profile: Yup.mixed().test('fileSize', 'File non valido', (value) => {
    if (typeof value === 'string') return false;
    if (value === undefined || !value.length) return true;
    return value[0].size <= 2000000;
  }),
});
