import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddNeedy from './AddNeedy';
import { addNeedy, fetchNeedy } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  needy: state.needy,
  mainLoading: state.mainLoading,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addNeedy,
  fetchNeedy,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNeedy);
