import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Reservations from './Reservations';

import { fetchUserReservations } from '../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  reservations: state.reservations,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUserReservations,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reservations);
