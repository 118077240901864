/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState, useEffect } from 'react';
import { Box, Grid, FormControlLabel } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment/min/moment-with-locales';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './style.css';

import ReservationDetail from '../../../components/reservation-detail';
import ReservationClosing from '../../../components/reservation-closing';
import ReservationRequestDetail from '../../../components/reservation-request-detail';
import CustomDropdown from '../../../components/custom-dropdown';
import CalendarToolbar from './CalendarToolbar';
import { getEventDates, getStatus } from '../../../libs/utils';
import Loader from '../../../components/loader';
import CustomInput from '../../../components/custom-input';
import CalendarFilters from './CalendarFilters';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

const Agenda = ({
  user, reservations, reservationRequests, fetchUserReservationRequests, fetchUserReservations, filters, updateCalendarFilters,
}) => {
  const [events, setEvents] = useState();
  const [filteredEvents, setFilteredEvents] = useState();
  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [showReservationClosing, setShowReservationClosing] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState();
  const [showReservationRequestDetail, setShowReservationRequestDetail] = useState(false);
  const [selectedReservationRequest, setSelectedReservationRequest] = useState();

  useEffect(() => {
    fetchUserReservationRequests();
    fetchUserReservations();
  }, []);

  useEffect(() => {
    setShowReservationDetail(selectedReservation !== undefined);
  }, [selectedReservation]);

  useEffect(() => {
    setShowReservationRequestDetail(selectedReservationRequest !== undefined);
  }, [selectedReservationRequest]);

  useEffect(() => {
    if (reservations !== undefined && reservationRequests !== undefined) {
      let localEvents = [];

      reservationRequests.forEach((rq) => {
        localEvents = [
          ...localEvents,
          ...getEventDates(rq).map(({ start, end }) => {
            const title = (() => {
              if (user.data.type === 'supplier') {
                return `Richiesta di prestazione di ${rq.needy.full_name}`;
              }
              return `Richiesta di prestazione a ${rq.supplier.full_name}`;
            })();

            return {
              title,
              start: start._d,
              end: end._d,
              allDay: false,
              resource: rq,
              type: 'reservation-request',
            };
          }),
        ];
      });

      reservations.forEach((r) => {
        localEvents = [
          ...localEvents,
          ...getEventDates(r.reservation_request).map(({ start, end }) => {
            const title = (() => {
              if (user.data.type === 'supplier') {
                return `Prestazione con ${r.reservation_request.needy.full_name}`;
              }
              return `Prestazione con ${r.reservation_request.supplier.full_name}`;
            })();

            return {
              title,
              start: start._d,
              end: end._d,
              allDay: false,
              resource: r,
              type: 'reservation',
            };
          }),
        ];
      });

      setEvents(localEvents);
    }
  }, [reservations, reservationRequests]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 && events !== undefined) {
      setFilteredEvents(events.filter((e) => {
        if (filters.selectedType) {
          if (filters.selectedType === 'Prestazioni' && e.type !== 'reservation') {
            return false;
          }

          if (filters.selectedType === 'Richieste' && e.type !== 'reservation-request') {
            return false;
          }
        }

        if (filters.selectedNeedy && filters.selectedNeedy !== 'Tutti') {
          if ((e.resource.reservation_request !== undefined
              && e.resource.reservation_request.needy.full_name !== filters.selectedNeedy)
              || (e.resource.needy !== undefined
                  && e.resource.needy.full_name !== filters.selectedNeedy)) {
            return false;
          }
        }

        if (filters.selectedStatus) {
          const status = getStatus(e.resource);
          if (filters.selectedStatus === 'Concluso' && status.value !== 'completed') return false;
          if (filters.selectedStatus === 'Confermato' && status.value !== 'accepted') return false;
          if (filters.selectedStatus === 'Rifiutato' && status.value !== 'refused') return false;
          if (filters.selectedStatus === 'In corso' && status.value !== 'in-progress') return false;
          if (filters.selectedStatus === 'In fase di valutazione' && status.value !== 'pending') return false;
          if (filters.selectedStatus === 'Cancellato' && (status.value !== 'deleted' && status.value !== 'canceled')) return false;
        }

        return true;
      }));
    }
  }, [events, filters]);

  const handleEventSelection = (event) => {
    if (event.type === 'reservation') {
      setSelectedReservation(event.resource);
    } else {
      setSelectedReservationRequest(event.resource);
    }
  };

  if (events === undefined) {
    return (
      <Loader />
    );
  }

  return (
    <div className="calendar-wrapper">
      <CalendarFilters />
      <Calendar
        onSelectEvent={handleEventSelection}
        eventPropGetter={(event) => ({ className: `${event.type} status-${event.resource.status.value}` })}
        localizer={localizer}
        events={filteredEvents || events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        components={
          {
            toolbar: CalendarToolbar,
          }
        }
      />

      <ReservationDetail
        onClose={() => {
          setShowReservationDetail(false);
          if (showReservationClosing === false) {
            setSelectedReservation(undefined);
          }
        }}
        onUpdate={() => {
          setShowReservationDetail(false);
          setSelectedReservation(undefined);
        }}
        onReservationCloseIntent={() => {
          setShowReservationDetail(false);
          setShowReservationClosing(true);
        }}
        visible={showReservationDetail}
        reservation={selectedReservation}
      />

      <ReservationRequestDetail
        onClose={() => {
          setShowReservationRequestDetail(false);
          setSelectedReservationRequest(undefined);
        }}
        onUpdate={() => {
          setShowReservationRequestDetail(false);
          setSelectedReservationRequest(undefined);
        }}
        visible={showReservationRequestDetail}
        reservationRequest={selectedReservationRequest}
      />

      <ReservationClosing
        onClose={() => {
          setShowReservationClosing(false);
          setSelectedReservation(undefined);
        }}
        visible={showReservationClosing}
        reservation={selectedReservation}
      />

    </div>
  );
};
export default Agenda;

//                 setSelectedReservation(reservation);
