import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddNeedyModal from './AddNeedyModal';

import {
  addNeedy,
  updateNeedy,
  deleteNeedy,
  fetchGenders,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  genders: state.genders,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addNeedy,
  updateNeedy,
  deleteNeedy,
  fetchGenders,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddNeedyModal);
