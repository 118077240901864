import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { getMediaUrl } from '../../../../libs/utils';

import './styles.css';

const Documents = ({ user, sendDocuments }) => {
  const [front, setFrond] = useState();

  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);

  const [pdfBackPreview, setPdfBackPreview] = useState();
  const [pdfFrontPreview, setPdfFrontPreview] = useState();

  const [back, setBack] = useState();
  const [frontUrl] = useState(user !== undefined && user.data !== undefined && user.data.info !== undefined && user.data.info.doc_front_path !== null ? user.data.info.doc_front_path : undefined);
  const [backUrl] = useState(user !== undefined && user.data !== undefined && user.data.info !== undefined && user.data.info.doc_front_path !== null ? user.data.info.doc_back_path : undefined);

  const IsLink = (fileUrl) => {
    return fileUrl.toLowerCase().endsWith("pdf");
  }


  const SetFrontFile = (e) => {
    let file = e.target.files[0];

    if (!file) {
      alert("si prega di caricare un file");
      return false;
    }

    if (file.type.includes("image") || file.type.includes("application/pdf")) {
      setFrond(file);
      setFrontPreview(URL.createObjectURL(file));
      if (file.type.includes("pdf")) {
        setPdfFrontPreview(true);
      } else {
        setPdfFrontPreview(false);
      }
    } else {
      alert("si prega di caricare un file immagine o pdf");
      return false;
    }

  }

  const setBackFile = (e) => {
    let file = e.target.files[0];

    if (!file) {
      alert("si prega di caricare un file");
      return false;
    }

    if (file.type.includes("image") || file.type.includes("application/pdf")) {
      setBack(file);
      setBackPreview(URL.createObjectURL(file));

      if (file.type.includes("pdf")) {
        setPdfBackPreview(true);
      } else {
        setPdfBackPreview(false);
      }

    } else {
      alert("si prega di caricare un file immagine o pdf");
      return false;
    }
  }

  return (
    <div>
      <p>Carica le foto del tuo documento d'identità</p>
      <h4 style={{ marginBottom: 10 }}>Fronte </h4>

      <div className="document-row">

        <div className='inputBoxUpload'>
          {frontUrl && IsLink(getMediaUrl(frontUrl)) && !frontPreview && !pdfFrontPreview &&
            <a href={getMediaUrl(frontUrl)} download >{frontUrl.split("/")[3]}</a>
          }

          {frontUrl && !IsLink(getMediaUrl(frontUrl)) && !frontPreview && !pdfFrontPreview &&
            <img src={getMediaUrl(frontUrl)} className="inputBoxUpdatePreview" />
          }

          {frontPreview && !pdfFrontPreview &&
            <img src={frontPreview} className="inputBoxUpdatePreview" />
          }

          {frontPreview && pdfFrontPreview &&
            <a href={getMediaUrl(frontPreview)} download >{frontPreview.split("/")[3]}</a>
          }

          <label for="file-upload-front" class="custom-file-upload">
            Scegli il file
          </label>
          <input id="file-upload-front" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(file) => SetFrontFile(file)} />
        </div>

      </div>


      <h4 style={{ marginTop: 40 }}>Retro</h4>
      <div className="document-row">

        <div className='inputBoxUpload'>
          {backUrl && IsLink(getMediaUrl(backUrl)) && !backPreview && !pdfBackPreview &&
            <a href={getMediaUrl(backUrl)} download >{frontUrl.split("/")[3]}</a>
          }

          {backUrl && !IsLink(getMediaUrl(backUrl)) && !backPreview && !pdfBackPreview &&
            <img src={getMediaUrl(backUrl)} className="inputBoxUpdatePreview" />
          }

          {backPreview && !pdfBackPreview &&
            <img src={backPreview} className="inputBoxUpdatePreview" />
          }

          {backPreview && pdfBackPreview &&
            <a href={getMediaUrl(backPreview)} download >{backPreview.split("/")[3]}</a>
          }



          <label for="file-upload-back" class="custom-file-upload">
            Scegli il file
          </label>
          <input id="file-upload-back" type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" onChange={(file) => setBackFile(file)} />
        </div>

      </div>

      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <Button
          style={{ marginLeft: 15 }}
          variant="yellow"
          disabled={!(front !== undefined && back !== undefined)}
          onClick={() => {
            sendDocuments({
              front,
              back,
            }, () => {
            });
          }}
        >
          Invia
        </Button>
      </div>

    </div>
  );
};

export default Documents;
