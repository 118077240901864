import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DashboardMenu from './DashboardMenu';

const mapStateToProps = (state) => ({
  user: state.user,
  savedSearch: state.savedSearch,
  savedSupplier: state.savedSupplier,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardMenu);
