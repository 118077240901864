import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { withRouter } from "react-router-dom";
import PreReservationStepForm from "../pre-reservation-step-form";

import "./styles.css";

const HomeServiceSelectionModal = ({
  visible,
  onClose,
  services,
  category,
  addSearchOption,
}) => {
  const [showForm, setShowForm] = useState();

  return (
    <>
      <Modal show={visible} onHide={onClose} backdrop="static">
        <Modal.Header style={{ borderWidth: 0 }} closeButton>
          <h5>Seleziona il servizio</h5>
        </Modal.Header>
        <Modal.Body className="reservation-from-modal-content">
          {services !== undefined &&
            services
              .filter((service) => service.category_id === category)
              .map((service) => (
                <Button
                  style={{ marginBottom: 16, marginRight: 16 }}
                  variant="yellow"
                  onClick={() => {
                    addSearchOption({
                      key: "service_id",
                      data: service.id,
                    });
                    onClose();
                    setShowForm(true);
                  }}
                >
                  {service.name}
                </Button>
              ))}
        </Modal.Body>
      </Modal>
      <PreReservationStepForm
        visible={showForm}
        onClose={() => setShowForm(false)}
      />
    </>
  );
};

export default withRouter(HomeServiceSelectionModal);
