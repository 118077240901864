export const SERVICES_FETCH = 'services/FETCH';
export const SERVICES_DELETE = 'services/DELETE';
export const SERVICES_ADD = 'services/ADD';
export const SERVICES_CATEGORIES_FETCH = 'services/FETCH_CATEGORIES';
export const FLUSH_RESERVATION_CONFIG = 'service/FLUSH_RESERVATION_CONFIG';
export const ADD_RESERVATION_OPTION = 'service/ADD_RESERVATION_OPTION';
export const CHANGE_RESERVATION_REQUEST_STATUS = 'service/CHANGE_RESERVATION_REQUEST_STATUS';
export const CHANGE_RESERVATION_STATUS = 'reservation/CHANGE_RESERVATION_STATUS';
export const NEW_SERVICE_REQUEST = 'services/NEW_REQUEST';

export const fetchServices = () => ({
  type: SERVICES_FETCH,
});

export const fetchCategoriesServices = () => ({
  type: SERVICES_CATEGORIES_FETCH,
});

export const flushReservationConfig = () => ({
  type: FLUSH_RESERVATION_CONFIG,
});

export const addReservationOption = (payload) => ({
  type: ADD_RESERVATION_OPTION,
  payload,
});

export const changeReservationRequestStatus = (payload) => ({
  type: CHANGE_RESERVATION_REQUEST_STATUS,
  payload,
});

export const changeReservationStatus = (payload, onSuccess, onError) => ({
  type: CHANGE_RESERVATION_STATUS,
  payload,
  onSuccess,
  onError,
});

export const newServiceRequest = (payload) => ({
  type: NEW_SERVICE_REQUEST,
  payload,
});

export const addService = () => ({
  type: SERVICES_ADD,
});

export const deleteServices = (payload, onSuccess, onError) => ({
  type: SERVICES_DELETE, payload, onSuccess, onError,
});

/*
export const SERVICES_FETCH = 'services/FETCH';
export const SERVICES_CATEGORIES_FETCH = 'services/FETCH_CATEGORIES';
export const FLUSH_RESERVATION_CONFIG = 'service/FLUSH_RESERVATION_CONFIG';
export const ADD_RESERVATION_OPTION = 'service/ADD_RESERVATION_OPTION';
export const SEND_GUEST_RESERVATION_REQUEST = 'reservation/SEND_GUEST_REQUEST';
export const SEND_RESERVATION_REQUEST = 'reservation/SEND_REQUEST';

export const fetchServices = () => ({
  type: SERVICES_FETCH,
});

export const fetchCategoriesServices = () => ({
  type: SERVICES_CATEGORIES_FETCH,
});

export const flushReservationConfig = () => ({
  type: FLUSH_RESERVATION_CONFIG,
});

export const addReservationOption = (payload) => ({
  type: ADD_RESERVATION_OPTION,
  payload,
});

export const sendGuestReservationRequest = () => ({
  type: SEND_GUEST_RESERVATION_REQUEST,
});

export const sendReservationRequest = () => ({
  type: SEND_RESERVATION_REQUEST,
});
*/
