/* eslint-disable no-shadow */
import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Container } from 'react-bootstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateCalendarFilters } from '../../../store/actions';
import CustomInput from '../../../components/custom-input';

const CalendarFilters = ({ user, filters, updateCalendarFilters }) => (
  <div className="filters-wrapper">
    <h6>Filtri</h6>

    <Grid container>


      {'needy' in user.data && (
        <Grid item xs={12} md={4}>
          <div className="form-input-wrapper">
            <CustomInput
              type="select"
              title="Seleziona l'anziano"
              options={['Tutti', ...user.data.needy.map((g) => g.full_name)]}
              onChange={(selectedNeedy) => updateCalendarFilters({ ...filters, selectedNeedy })}
              value={filters.selectedNeedy || 'Tutti'}
              id="needy"
              label="Anziano"
              variant="outlined"
            />
          </div>
        </Grid>
      )}

      <Grid item xs={12} md={4}>
        <div className="form-input-wrapper">
          <CustomInput
            type="select"
            title="Seleziona la tipologia di eventi che vuoi vedere"
            options={['Tutti', 'Prestazioni', 'Richieste']}
            onChange={(selectedType) => updateCalendarFilters({ ...filters, selectedType })}
            value={filters.selectedType || 'Tutti'}
            id="type"
            label="Tipologia evento"
            variant="outlined"
          />
        </div>
      </Grid>

      <Grid item xs={12} md={4}>
        <div className="form-input-wrapper">
          <CustomInput
            type="select"
            title="Seleziona lo stato degli eventi che vuoi vedere"
            options={['Tutti', 'Confermato', 'Rifiutato', 'Concluso', 'In corso', 'In fase di valutazione', 'Cancellato']}
            onChange={(selectedStatus) => updateCalendarFilters({ ...filters, selectedStatus })}
            value={filters.selectedStatus || 'Tutti'}
            id="status"
            label="Stato evento"
            variant="outlined"
          />
        </div>
      </Grid>

    </Grid>


  </div>
);

const mapStateToProps = (state) => ({
  user: state.user,
  filters: state.calendarFilters,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateCalendarFilters,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarFilters);
