import React from 'react';
import TextEllipsis from 'react-text-ellipsis';
import { Grid, Box } from '@material-ui/core';
import moment from 'moment/min/moment-with-locales';

import './style.css';

import {
  getMediaUrl, getPrice, formatEventDatetime, parseTotalPrice,
} from '../../libs/utils';

const ReservationRow = ({ reservation, onClick, user }) => {
  const whenString = formatEventDatetime(reservation.reservation_request);

  const profileImage = user.data && user.data.type === 'supplier'
    ? getMediaUrl(reservation.reservation_request.needy.media.profile)
    : getMediaUrl(reservation.reservation_request.supplier.media.profile);

  const userFullName = user.data && user.data.type === 'supplier'
    ? `${reservation.reservation_request.needy.first_name} ${reservation.reservation_request.needy.last_name}`
    : reservation.reservation_request.supplier.full_name;

  return (
    <Grid onClick={onClick} key={reservation.id} className="reservation-row-wrapper" item xs={12}>
      <Box boxShadow={3} className="reservation-row">
        <div className="supplier-wrapper">
          <img src={profileImage} alt="Supplier" />
          <div className="supplier-texts-wrapper">
            <TextEllipsis
              lines={1}
              tag="h6"
              ellipsisChars="..."
              tagClass="supplier-title"
              debounceTimeoutOnResize={200}
              useJsOnly
            >
              {userFullName}
            </TextEllipsis>
            {/* {user.data && user.data.type !== 'supplier' && <span className="supplier-level">{reservation.reservation_request.supplier.level}</span>} */}
          </div>
          <div className="yellow-spacer" />
        </div>
        <div className="reservation-info">
          <div>
            <div className="reservation-service">
              <span className="supplier-info-label">Servizio richiesto</span>
              <span className="supplier-info-value">{reservation.reservation_request.service.name}</span>
            </div>
            <div>
              <span className="supplier-info-label">Quando</span>
              <span className="supplier-info-value">{whenString}</span>
            </div>
            <div>
              <span className="supplier-info-label">Costo</span>
              <span className="supplier-info-value">{parseTotalPrice(reservation.reservation_request.price_at_request_time, reservation.reservation_request.service.duration, reservation.reservation_request.timeslots.length)}</span>

            </div>
          </div>

          {reservation.reservation_request.notes && (
          <div style={{ marginTop: 10 }}>
            <div>
              <span className="supplier-info-label">Note</span>
              <span className="supplier-info-value">{reservation.reservation_request.notes}</span>
            </div>
          </div>
          )}

        </div>
      </Box>
    </Grid>
  );
};

export default ReservationRow;
