import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as thunks from './middlewares';
import rootReducer from './reducers';


const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

middlewares.push(thunk);

Object.keys(thunks).forEach((key) => {
  middlewares.push(thunks[key]);
});

const persistConfig = {
  key: 'root',
  whitelist: ['user', 'savedSearch', 'savedSupplier', 'search'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares),
);

export const persistor = persistStore(store);


// persistor.purge();
