import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import LoginModal from './LoginModal';

import {
  login,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  login,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginModal);
