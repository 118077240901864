import { reducerFromMap } from './commons.reducers';
import {
  SERVICES_FETCH, SERVICES_CATEGORIES_FETCH, ADD_RESERVATION_OPTION, CHANGE_RESERVATION_STATUS,
} from '../actions';

const reducerMap = {};

reducerMap[SERVICES_FETCH] = (state) => ({
  ...state,
  loading: true,
  services: undefined,
});

reducerMap[`${SERVICES_FETCH}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  services: action.payload.data.data,
});

reducerMap[`${SERVICES_FETCH}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
});

reducerMap[SERVICES_CATEGORIES_FETCH] = (state) => ({
  ...state,
  loading: true,
  serviceCategories: undefined,
});

reducerMap[`${SERVICES_CATEGORIES_FETCH}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  serviceCategories: action.payload.data.data,
});

reducerMap[`${SERVICES_CATEGORIES_FETCH}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
});

reducerMap[ADD_RESERVATION_OPTION] = (state, action) => ({
  ...state,
  reservation: {
    ...state.reservation,
    [action.payload.key]: action.payload.data,
  },
});

reducerMap[CHANGE_RESERVATION_STATUS] = (state) => ({
  ...state,
  loading: true,
});

reducerMap[`${CHANGE_RESERVATION_STATUS}_SUCCESS`] = (state) => ({
  ...state,
  loading: false,
});

reducerMap[`${CHANGE_RESERVATION_STATUS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
});

const reducer = reducerFromMap(reducerMap);

export default reducer;
