/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withRouter } from 'react-router-dom';


const CustomTitle = ({ title, history }) => (
  <div>
    <h6
      onClick={() => history.goBack()}
      style={{ justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
    >
      <KeyboardBackspaceIcon fontSize="small" />
      <span style={{ top: 2, left: 8, position: 'relative' }}>Torna indietro</span>
    </h6>
    <h2>{title}</h2>
  </div>
);

export default withRouter(CustomTitle);
