import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SupplierPage from './SupplierPage';

import { updateUserData, updateUserProfilePhoto, updateUserCoverPhoto } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUserData,
  updateUserProfilePhoto,
  updateUserCoverPhoto,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierPage);
