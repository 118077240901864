import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Main from './Main';
import {
  fetchCategoriesServices, fetchServices, performSearch, flushParam,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  services: state.services,
  serviceCategories: state.serviceCategories,
  search: state.search,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCategoriesServices,
  fetchServices,
  performSearch,
  flushParam,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Main);
