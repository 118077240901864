import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import './style.css';

function ProfileMediaInput({ onFileSelected }) {
  const {
    acceptedFiles, rejectedFiles, getRootProps, getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: false,
  });

  const acceptedFilesItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path}
      {' '}
-
      {' '}
      {file.size}
      {' '}
bytes
    </li>
  ));

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onFileSelected(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Trascina qui il file, oppure fai click per selezionare dal tuo dispositivo</p>
        <em>(Soltanto file di tipo *.jpeg and *.png saranno accettati)</em>
      </div>
      {acceptedFilesItems.length > 0 && (
      <aside style={{ marginTop: 20 }}>
        <h6>Stai per caricare la seguente foto profilo</h6>
        <ul>
          {acceptedFilesItems}
        </ul>
      </aside>
      )}
    </section>
  );
}

export default ProfileMediaInput;
