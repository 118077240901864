import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReservationRequests from './ReservationRequests';
import { fetchUserSelf, fetchUserReservationRequests } from '../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  reservationRequests: state.reservationRequests,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUserSelf,
  fetchUserReservationRequests,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationRequests);
