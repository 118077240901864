/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import WorkIcon from '@material-ui/icons/Work';

import './style.css';

import Loader from '../loader';

const userItems = [
  {
    title: 'Area personale',
    key: 'dashboard',
    icon: HomeIcon,
  },
  {
    title: 'Agenda',
    key: 'calendario',
    icon: CalendarTodayIcon,
  },
  {
    title: 'Profilo',
    key: 'preferenze',
    icon: PersonIcon,
  },
  {
    title: 'Richieste',
    key: 'richieste',
    icon: BeenhereIcon,
  },
  {
    title: 'Prestazioni',
    key: 'prestazioni',
    icon: InsertCommentIcon,
  },
];

const supplierItems = [
  {
    title: 'Area personale',
    key: 'dashboard',
    icon: HomeIcon,
  },
  {
    title: 'Agenda',
    key: 'calendario',
    icon: CalendarTodayIcon,
  },
  {
    title: 'Scheda fornitore',
    key: 'fornitore',
    icon: WorkIcon,
  },
  {
    title: 'Profilo',
    key: 'preferenze',
    icon: PersonIcon,
  },
  {
    title: 'Richieste',
    key: 'richieste',
    icon: BeenhereIcon,
  },
  {
    title: 'Prestazioni',
    key: 'prestazioni',
    icon: InsertCommentIcon,
  },
];

const DashboardMenu = ({
  user, history, activeItem, savedSearch, savedSupplier,
}) => {
  const [items, setItems] = useState();

  useEffect(() => {
    if (user && user.data) {
      if (user.data.type === 'carer' || user.data.type === 'needy') {
        setItems(userItems);
      } else {
        setItems(supplierItems);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && user.data) {
      if (user.data.type === 'carer' || user.data.type === 'needy') {
        setItems(userItems);
      } else {
        setItems(supplierItems);
      }
    }
  }, [user]);

  useEffect(() => {
    if (savedSearch && savedSupplier) {
      history.push(`/fornitore/${savedSupplier.id}`);
    }
  }, []);

  if (user === undefined || items === undefined) return <Loader />;

  return (

    <Box className="dashboard-menu" boxShadow={2} style={{ borderRadius: 6, padding: 20 }}>
      {items.map((item, index) => (
        <div
          onClick={() => {
            history.push(index === 0 ? '/dashboard' : `/dashboard/${item.key}`);
          }}
          className={item.key === activeItem ? 'dashboard-menu-item selected' : 'dashboard-menu-item'}
        >
          <item.icon style={{ color: '#004252', fontSize: 40 }} />
          <span>{item.title}</span>
        </div>
      ))}
    </Box>
  );
};

export default withRouter(DashboardMenu);
