import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { isMobile, isTablet } from 'react-device-detect';


import CustomInput from '../../../components/custom-input';
import './styles.css';

const PasswordResetForm = ({ resetUserPassword, history }) => {
  const [sent, setSent] = useState(false);
  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('L\'email non è valida')
      .required('Campo obbligatorio'),
    email_confirm: Yup.string()
      .test('checkEmailConfirmation', 'Non è uguale alla email inserita', function (value) {
        const email = this.resolve(Yup.ref('email'));
        return email === value;
      })
      .required('Campo obbligatorio'),
  });

  const handleConfirm = (values) => {
    const data = {
      email: values.email,
      email_confirmation: values.email_confirm,
    };

    resetUserPassword(data, () => {
      setSent(true);
      Swal.fire('Ok!', 'Controlla la tua casella di posta elettronica.', 'success');
    }, (err)=>{
      console.log(err);
      Swal
        .fire('Errore!', "Indirizzo email errato. Se non te lo ricordi contatta il Servizio Clienti.", 'error');
    });

    // registerUser(data, () => {
    //   history.push('/registration/confirm-email');
    // });
  };

  return (
    <div className="password-reset-form-wrapper">
      <Container className="password-reset">
        <Container container>
          <h3>Reset della password</h3>
          <p>
            Inserisci la email con cui ti sei registrato a My Seren Care!
            <br />
            Nel caso in cui fosse presente nel sistema un utente con quella mail, ti verrà inviata una mail con un collegamento ipertestuale per effettuare il reset della password
          </p>
          <Formik
            validationSchema={ValidationSchema}
            validateOnChange
            initialValues={{
              email: '',
              email_confirm: '',
            }}
            onSubmit={handleConfirm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="form-input-wrapper">
                      <CustomInput
                        required
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                        id="email"
                        label="Email"
                        variant="outlined"
                        error={touched.email && errors.email}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="form-input-wrapper">
                      <CustomInput
                        required
                        onChange={handleChange('email_confirm')}
                        onBlur={handleBlur('email_confirm')}
                        value={values.email_confirm}
                        error={touched.email_confirm && errors.email_confirm}
                        id="email_confirm"
                        label="Conferma email"
                        variant="outlined"
                      />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'right' }}>
                  <Button disabled={Object.keys(errors).length > 0 || sent} type="submit" onClick={handleSubmit} variant="yellow">Invia mail di reset password</Button>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(PasswordResetForm);
