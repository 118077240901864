import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logout } from '../../store/actions';
import UserIconTopBar from './UserIconTopBar';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  logout,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserIconTopBar);
