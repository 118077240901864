import { reducerFromMap } from './commons.reducers';
import { SUPPLIER_FETCH, FETCH_TIMESLOTS } from '../actions';

const reducerMap = {};

reducerMap[SUPPLIER_FETCH] = (state) => ({
  ...state,
  loading: true,
  supplier: undefined,
});

reducerMap[`${SUPPLIER_FETCH}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  supplier: action.payload.data.data,
});

reducerMap[`${SUPPLIER_FETCH}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
});

reducerMap[FETCH_TIMESLOTS] = (state) => ({
  ...state,
  loading: true,
  timeslots: undefined,
});

reducerMap[`${FETCH_TIMESLOTS}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  timeslots: action.payload.data,
});

reducerMap[`${FETCH_TIMESLOTS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: action.payload.data,
});

const reducer = reducerFromMap(reducerMap);

export default reducer;
