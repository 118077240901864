import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NeedySettings from './NeedySettings';

import { addNeedy, deleteNeedy } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addNeedy,
  deleteNeedy,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NeedySettings);
