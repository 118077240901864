/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';


import { withRouter } from 'react-router-dom';
import Loader from '../../../components/loader';

import SupplierPage from './page';
import SupplierServices from './services';
import SupplierContacts from './contacts';
import SupplierAddresses from '../settings/addresses';
import SupplierDocuments from '../settings/documents';
import SupplierMaxDistance from '../settings/max-distance';
import SupplierAvailability from '../settings/availability';

import './styles.css';

const menuItems = [
  { title: 'Scheda', key: 'pagina' },
  { title: 'Servizi', key: 'servizi' },
  { title: 'Disponibilità', key: 'disponibilita' },
  { title: 'Contatti', key: 'contatti' },
  { title: 'Indirizzi', key: 'indirizzi' },
  { title: 'Documenti', key: 'documenti' },
  { title: 'Distanza massima', key: 'massima-distanza' },
];

const Supplier = ({ user, history }) => {
  const [setting, setSetting] = useState();

  useEffect(() => {
    const items = history.location.pathname.split('/');

    if (items.length > 3) {
      setSetting(items[3]);
    } else if (items.length === 3 && items[2] === 'fornitore') {
      history.push('/dashboard/fornitore/pagina');
    }
  }, [history.location]);


  const renderSettingContent = useCallback(() => {
    if (setting === 'pagina') return <SupplierPage />;
    if (setting === 'servizi') return <SupplierServices />;
    if (setting === 'contatti') return <SupplierContacts />;
    if (setting === 'indirizzi') return <SupplierAddresses />;
    if (setting === 'documenti') return <SupplierDocuments />;
    if (setting === 'massima-distanza') return <SupplierMaxDistance />;
    if (setting === 'disponibilita') return <SupplierAvailability />;


    return <Loader />;
  }, [setting]);

  const renderSettingsMenu = useCallback(() => menuItems.map((item, index) => (
    <div
      onClick={() => {
        history.push(`/dashboard/fornitore/${item.key}`);
      }}
      className={item.key === setting ? 'dashboard-settings-menu-item selected' : 'dashboard-settings-menu-item '}
    >
      <span>{item.title}</span>
    </div>
  )), [setting]);

  return (
    <Container className="settings-wrapper">
      <Grid container>
        <Grid xs={12} md={4} style={{ paddingRight: 30 }}>
          {renderSettingsMenu()}
        </Grid>
        <Grid xs={12} md={8}>
          {renderSettingContent()}
        </Grid>
      </Grid>

    </Container>
  );
};
export default withRouter(Supplier);
