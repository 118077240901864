import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReservationRequestDetail from './ReservationRequestDetail';

import {
  userCancelReservationRequest,
  userAcceptReservationRequest,
  userRefuseReservationRequest,
  downloadInvoice,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  userCancelReservationRequest,
  userAcceptReservationRequest,
  userRefuseReservationRequest,
  downloadInvoice,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationRequestDetail);
