import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddContactModal from './AddContactModal';

import {
  addSupplierContact,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addSupplierContact,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddContactModal);
