import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const PayPalButton = ({ history, sendReservationRequest, setLoading, price, incrementStep }) => {

  useEffect(() => {
  
    const addPayPalScript = () => {
     
      const existingScript = document.getElementById('paypal-script');
      if (existingScript) {
        existingScript.remove();
      }

      const script = document.createElement("script");
      script.id = 'paypal-script'; 
      console.log(process.env.REACT_APP_PAYPAL_ID)
      script.src = `https://www.paypal.com/sdk/js?client-id=`+process.env.REACT_APP_PAYPAL_ID;
      script.async = true;
      script.onload = () => {
        window.paypal.Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: price,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            setLoading(true);
            try {
              const details = await actions.order.capture();
              Swal.fire("Successo", `Transazione completata da ${details.payer.name.given_name}`, "success");

              // Incrementa lo step dopo il successo del pagamento
              incrementStep();

              sendReservationRequest(history);
            } catch (error) {
              Swal.fire('Errore', 'Errore durante la cattura della transazione.', 'error');
            } finally {
              setLoading(false);
            }
          },
          onError: (err) => {
            console.error("Errore nel pagamento", err);
            Swal.fire('Errore', 'Errore durante il pagamento.', 'error');
          },
        }).render("#paypal-button-container");
      };
      document.body.appendChild(script);
    };

    addPayPalScript();
  }, [price, history, sendReservationRequest, setLoading, incrementStep]);

  return <div id="paypal-button-container"></div>;
};

export default PayPalButton;
