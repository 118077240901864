import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierCalendar from './SupplierCalendar';

import { fetchTimeSlots, addSearchOption } from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  supplier: state.supplier,
  search: state.search,
  timeslots: state.timeslots,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchTimeSlots,
  addSearchOption,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierCalendar);
