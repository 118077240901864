import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { flushErrors, flushMessage } from '../../store/actions';
import ErrorHandler from './ErrorHandler';

const mapStateToProps = (state) => ({
  error: state.error,
  message: state.message,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  flushErrors,
  flushMessage,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorHandler);
