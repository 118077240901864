import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  InputGroup, FormControl,
} from 'react-bootstrap';
import { Search } from '@material-ui/icons';


import './HomeSearch.css';

import { Button } from '@material-ui/core';
import PreReservationStepForm from '../pre-reservation-step-form';
import RequestServiceModal from '../request-service-modal';

export default ({ services, serviceCategories, addSearchOption }) => {
  const [searchInput, setSearchInput] = useState('');
  const [servicesToShow, setServicesToShow] = useState([]);
  const [showSelectionBox, setShowSelectionBox] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showServiceRequestForm, setShowServiceRequestForm] = useState(false);

  useEffect(() => {
    if (services) {
      if (searchInput.length === 0) {
        setServicesToShow(services);
      } else {
        setServicesToShow(
          services
            .filter((service) => (
              service.description).toLowerCase().includes(searchInput)
                  || (service.name).toLowerCase().includes(searchInput.toLowerCase())),
        );
      }
    }
  }, [searchInput, services]);

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setShowSelectionBox(false);
    }, 300);
  };

  const handleInputFocus = () => {
    setShowSelectionBox(true);
  };

  const handleServiceClick = (service) => {
    addSearchOption({
      key: 'service_id',
      data: service.id,
    });

    setShowForm(true);
  };

  const renderSelectionBox = useCallback(() => {
    if (serviceCategories === undefined || !showSelectionBox) return null;
    const rawData = {};
    const data = [];

    servicesToShow.forEach((service) => {
      if (service.category_id in rawData) {
        rawData[service.category_id].push(service);
      } else {
        rawData[service.category_id] = [service];
      }
    });

    Object.keys(rawData).forEach((cat) => {
      const category = serviceCategories.find((item) => item.id === parseInt(cat, 10));
      data.push({
        title: category.name,
        category,
        data: rawData[cat],
      });
    });

    if (data.length === 0) {
      return (
        <div className="section-list">
          <div>
            <ul className="service-group">
              <li className="service-name">
                <Button onClick={() => setShowServiceRequestForm(true)}>
                  Non trovi il servizio desiderato? Richiedilo!
                </Button>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div className="section-list">
        {data.map((category) => (
          <div>
            <p className="section-title">{category.title}</p>
            <ul className="service-group">
              {category.data.map((service) => (
                <li className="service-name">
                  <Button onClick={() => handleServiceClick(service)}>{service.name}</Button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }, [servicesToShow, showSelectionBox]);

  return (
    <div className="search-box" style={{ paddingTop: 20 }}>
      <h2>Trova il professionista perfetto per i tuoi bisogni</h2>
      <div>
        <InputGroup className="mb-3 input-wrapper">
          <InputGroup.Prepend className="input-icon-wrapper">
            <Search style={{ color: '#E6E6E6' }}>filled</Search>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Cosa stai cercando?"
            aria-label="Cosa stai cercando?"
            aria-describedby="basic-addon2"
            className="input"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
          />
          {renderSelectionBox()}
        </InputGroup>


      </div>
      <PreReservationStepForm visible={showForm} onClose={() => setShowForm(false)} />
      <RequestServiceModal visible={showServiceRequestForm} onClose={() => setShowServiceRequestForm(false)} />
    </div>

  );
};
