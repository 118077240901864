export const USER_LOGIN = 'user/LOGIN';
export const USER_LOGOUT = 'user/LOGOUT';
export const USER_UPDATE_DATA = 'user/UPDATE_DATA';
export const USER_UPDATE_PROFILE_PHOTO = 'user/UPDATE_PROFILE_PHOTO';
export const USER_UPDATE_COVER_PHOTO = 'user/UPDATE_COVER_PHOTO';
export const USER_FETCH_SELF = 'user/FETCH_SELF';
export const USER_FETCH_RESERVATION_REQUESTS = 'user/FETCH_RESERVATION_REQUESTS';
export const USER_FETCH_RESERVATIONS = 'user/FETCH_RESERVATIONS';
export const USER_SET_REGISTRATION_DATA = 'user/SET_REGISTRATION_DATA';
export const USER_FETCH_SERVICES = 'user/FETCH_SERVICES';
export const USER_REGISTER = 'user/REGISTER';
export const FETCH_GENDERS = 'genders/FETCH';
export const FETCH_NEEDY = 'needy/FETCH';
export const DELETE_NEEDY = 'needy/DELETE';
export const ADD_NEEDY = 'needy/ADD';
export const UPDATE_NEEDY = 'needy/UPDATE';
export const FETCH_ADDRESSES = 'addresses/FETCH';
export const ADD_ADDRESSES = 'addresses/ADD';
export const DELETE_ADDRESSES = 'addresses/DELETE';
export const FETCH_PAYMENT_METHODS = 'paymentMethods/FETCH';
export const ADD_PAYMENT_METHODS = 'paymentMethods/ADD';
export const DELETE_PAYMENT_METHODS = 'paymentMethods/DELETE';
export const SEND_RESERVATION_REQUEST = 'reservation/SEND_REQUEST';
export const SEND_GUEST_RESERVATION_REQUEST = 'reservation/SEND_GUEST_REQUEST';
export const SAVE_RESERVATION_REQUEST = 'reservation/SAVE';
export const CANCEL_RESERVATION_SUPPLIER = 'reservation/CANCEL_SUPPLIER';
export const CANCEL_RESERVATION_USER = 'reservation/CANCEL_USER';
export const CANCEL_RESERVATION_REQUEST_USER = 'reservation/CANCEL_REQUEST_USER';
export const NEEDY_UPDATE_PHOTO = 'needy/NEEDY_UPDATE_PHOTO';
export const NEEDY_UPDATE_COVER_PHOTO = 'needy/NEEDY_UPDATE_COVER_PHOTO';
export const NEEDY_UPDATE_DATA = 'needy/UPDATE_DATA';
export const USER_UPDATE_SERVICE = 'user/UPDATE_SERVICE';
export const USER_ADD_SERVICE = 'user/ADD_SERVICE';
export const SEND_RESERVATION_REVIEW = 'reservation/SEND_REVIEW';
export const USER_RESET_PASSWORD = 'user/RESET_PASSWORD';
export const USER_SEND_NEW_PASSWORD = 'user/SEND_NEW_PASSWORD';
export const USER_DOWNLOAD_INVOICE = 'invoice/DOWNLOAD_INVOICE';


export const login = (payload, onSuccess, onError) => ({
  type: USER_LOGIN,
  payload,
  onSuccess,
  onError,
});

export const logout = (onSuccess) => ({
  type: USER_LOGOUT,
  onSuccess,
});

export const fetchUserSelf = () => ({
  type: USER_FETCH_SELF,
});

export const fetchUserReservationRequests = () => ({
  type: USER_FETCH_RESERVATION_REQUESTS,
});

export const fetchUserReservations = () => ({
  type: USER_FETCH_RESERVATIONS,
});

export const registerUser = (data, onSuccess, onError) => ({
  type: USER_REGISTER, payload: data, onSuccess, onError,
});

export const fetchGenders = () => ({
  type: FETCH_GENDERS,
});

export const fetchNeedy = () => ({
  type: FETCH_NEEDY,
});

export const addNeedy = (payload, onSuccess, onError) => ({
  type: ADD_NEEDY, payload, onSuccess, onError,
});

export const updateNeedy = (payload, onSuccess, onError) => ({
  type: UPDATE_NEEDY, payload, onSuccess, onError,
});

export const fetchAddresses = () => ({
  type: FETCH_ADDRESSES,
});

export const addAddresses = (payload, onSuccess, onError) => ({
  type: ADD_ADDRESSES, payload, onSuccess, onError,
});

export const deleteAddresses = (payload, onSuccess, onError) => ({
  type: DELETE_ADDRESSES, payload, onSuccess, onError,
});

export const fetchPaymentMethods = () => ({
  type: FETCH_PAYMENT_METHODS,
});

export const addPaymentMethods = (data, onSuccess, onError) => ({
  type: ADD_PAYMENT_METHODS,
  payload: data,
  onSuccess,
  onError,
});

export const deletePaymentMethods = (payload, onSuccess, onError) => ({
  type: DELETE_PAYMENT_METHODS,
  payload,
  onSuccess,
  onError,
});

export const sendReservationRequest = (history) => ({
  type: SEND_RESERVATION_REQUEST,
  history,
});

export const sendGuestReservationRequest = () => ({
  type: SEND_GUEST_RESERVATION_REQUEST,
});

export const saveReservation = () => ({
  type: SAVE_RESERVATION_REQUEST,
});

export const supplierCancelReservation = (reservationId) => ({
  type: CANCEL_RESERVATION_SUPPLIER,
  payload: {
    reservationId,
  },
});

export const userCancelReservation = (payload) => ({
  type: CANCEL_RESERVATION_USER, payload,
});

export const userCancelReservationRequest = (reservationRequestId) => ({
  type: CANCEL_RESERVATION_REQUEST_USER,
  payload: {
    reservationRequestId,
  },
});

export const updateUserData = (data, onSuccess, onError) => ({
  type: USER_UPDATE_DATA,
  payload: data,
  onSuccess,
  onError,
});

export const updateUserProfilePhoto = (data, onSuccess, onError) => ({
  type: USER_UPDATE_PROFILE_PHOTO,
  payload: data,
  onSuccess,
  onError,
});

export const updateUserCoverPhoto = (data, onSuccess, onError) => ({
  type: USER_UPDATE_COVER_PHOTO,
  payload: data,
  onSuccess,
  onError,
});

export const updateNeedyData = (data) => ({
  type: NEEDY_UPDATE_DATA,
  payload: data,
});

export const updateUserService = (data, onSuccess, onError) => ({
  type: USER_UPDATE_SERVICE,
  payload: data,
  onSuccess,
  onError,
});

export const addUserService = (data, onSuccess, onError) => ({
  type: USER_ADD_SERVICE,
  payload: data,
  onSuccess,
  onError,
});

export const sendReservationReview = (data, onSuccess, onError) => ({
  type: SEND_RESERVATION_REVIEW,
  payload: data,
  onSuccess,
  onError,
});

export const fetchUserServices = () => ({
  type: USER_FETCH_SERVICES,
});

export const updateNeedyPhoto = (data) => ({
  type: NEEDY_UPDATE_PHOTO,
  payload: data,
});

export const updateNeedyCoverPhoto = (data) => ({
  type: NEEDY_UPDATE_COVER_PHOTO,
  payload: data,
});

export const deleteNeedy = (payload, onSuccess, onError) => ({
  type: DELETE_NEEDY, payload, onSuccess, onError,
});

export const resetUserPassword = (payload, onSuccess, onError) => ({
  type: USER_RESET_PASSWORD,
  payload,
  onSuccess,
  onError,
});

export const sendUserNewPassword = (payload, onSuccess, onError) => ({
  type: USER_SEND_NEW_PASSWORD,
  payload,
  onSuccess,
  onError,
});

export const downloadInvoice = (payload, onSuccess, onError) => ({
  type: USER_DOWNLOAD_INVOICE,
  payload,
  onSuccess,
  onError,
});
