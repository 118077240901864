import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal, Button, Dropdown,
} from 'react-bootstrap';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

import { Elements, CardElement } from 'react-stripe-elements';
import DatePicker, { registerLocale } from 'react-datepicker';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { withRouter } from 'react-router-dom';


import CustomInput from '../../../../../components/custom-input';
import Loader from '../../../../../components/loader';

import colors from '../../../../../res/colors';

const AddPaymentModal = ({
  visible, onClose, addPaymentMethods,
}) => {
  const [fullName, setFullName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [expiry, setExpiry] = useState();
  const [cvc, setCvc] = useState();
  const {
    meta,
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();


  const handleSubmit = () => {
    const [month, year] = expiry.split('/').map((s) => s.trim());

    const data = {
      type: 'card',
      card: {
        number: cardNumber,
        exp_month: month,
        exp_year: year,
        cvc,
      },
      billing_details: {
        name: fullName,
      },
    };

    addPaymentMethods(data, onClose);
  };

  const isValid = () => meta.touchedInputs.cardNumber && meta.touchedInputs.expiryDate && meta.touchedInputs.cvc && meta.error === undefined;

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5>Aggiungi un metodo di pagamento</h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">

        <PaymentInputsWrapper {...wrapperProps} style={{ width: '100%', marginBottom: 16 }}>
          <svg {...getCardImageProps({ images })} />
          <input {...getCardNumberProps({ onChange: (e) => setCardNumber(e.target.value) })} />
          <input {...getExpiryDateProps({ onChange: (e) => setExpiry(e.target.value) })} />
          <input {...getCVCProps({ onChange: (e) => setCvc(e.target.value) })} />
        </PaymentInputsWrapper>

        <CustomInput
          type="text"
          autoFocus
          label="Intestatario"
          variant="outlined"
          color={colors.primary.normal}
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />

        <div style={{ textAlign: 'center', marginBottom: 20, marginTop: 30 }}>
          <Button style={{ marginRight: 15 }} onClick={onClose} variant="red">Annulla</Button>
          <Button disabled={!isValid()} style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} variant="yellow">Continua</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(AddPaymentModal);
