import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import AddNeedyModal from '../../../../components/add-needy-modal';
import './style.css';
import colors from '../../../../res/colors';
import Loader from '../../../../components/loader';

const AddNeedy = ({
  fetchNeedy, needy, mainLoading, history,
}) => {
  const [showModal, setShowmodal] = useState(false);

  useEffect(() => {
    fetchNeedy();
  }, []);

  return (
    <div className="carer-add-needy-wrapper" style={{ padding: 40 }}>
      <Container>
        <Container style={{ position: 'relative', height: '50vh' }} container>
          <h3>Registrazione familiare</h3>
          <p>Aggiungi adesso le persone per cui intendi prenotare i servizi.</p>
          <p>Potrai aggiungerne altre in seguito.</p>
          <p>Attenzione: inserisci almeno un anziano per prenotare i servizi.</p>
          <p>Le prestazioni si terrano all'indirizzo inserito in fase di registrazione.</p>


          <div style={{ marginBottom: 20 }}>
            {needy === undefined ? (<Loader />) : needy.map((item) => (
              <h5>{item.full_name}</h5>
            ))}
          </div>


          <AddCircleIcon onClick={() => setShowmodal(true)} style={{ color: colors.secondary.normal, fontSize: 40 }}>filled</AddCircleIcon>
          <div style={{
            position: 'absolute', right: 30, bottom: 100, textAlign: 'right',
          }}
          >
            <Button
              type="submit"
              onClick={() => {
                history.push('/dashboard');
              }}
              variant="yellow"
            >
              Concludi registrazione
            </Button>
          </div>

          <AddNeedyModal
            visible={showModal}
            onClose={() => {
              setShowmodal(false);
              fetchNeedy();
            }}
          />
        </Container>
      </Container>


    </div>
  );
};
export default withRouter(AddNeedy);
