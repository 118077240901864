import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchSupplier } from '../../store/actions';
import Supplier from './Supplier';

const mapStateToProps = (state) => ({
  user: state.user,
  supplier: state.supplier,
  savedSearch: state.savedSearch,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSupplier,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Supplier);
