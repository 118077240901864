/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';

import Search from '../containers/search';


export default () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <Search />
      </Route>
    </Switch>
  );
};
