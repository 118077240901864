import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RegistrationForm from './RegistrationForm';
import { registerUser, fetchGenders } from '../../../store/actions';

const mapStateToProps = (state) => ({
  genders: state.genders,
  loading: state.loading,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  registerUser,
  fetchGenders,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationForm);
