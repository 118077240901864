export default {
  primary: {
    normal: '#004252',
    light: '#3a6d7e',
    dark: '#001c29',
    on: '#FFFFFF',
  },
  secondary: {
    normal: '#e4ac04',
    light: '#ffdd4b',
    dark: '#ad7d00',
    on: '#FFFFFF',
  },
  background: {
    normal: '#fafafa',
    light: '#ffffff',
    dark: '#c7c7c7',
    on: '#0000000',
  },
  surface: {
    normal: '#fafafa',
    light: '#ffffff',
    dark: '#c7c7c7',
    on: '#0000000',
  },
  error: {
    normal: '#ef9a9a',
    light: '#ffcccb',
    dark: '#ba6b6c',
    on: '#0000000',
  },
  text: {
    normal: '#212121',
    light: '#484848',
    dark: '#000000',
    on: '#FFF',
  },
  black: {
    normal: '#000000',
    light: '#dedede',
    dark: '#000000',
    on: '#0000000',
  },
  white: {
    normal: '#FFFFFF',
    light: '#ffcccb',
    dark: '#ba6b6c',
    on: '#0000000',
  },
  grey: {
    normal: '#707070',
    light: '#E6E6E6',
  },
  red: {
    normal: '#E46304',
    light: '#E6E6E6',
  },
  green: {
    normal: '#A7DE10',
    light: '#E6E6E6',
  },
};
