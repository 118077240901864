import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RegistrationChoice from './RegistrationChoice';

const mapStateToProps = (state) => ({
  search: state.search,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationChoice);
