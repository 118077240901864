import { CALENDAR_UPDATE_FILTERS } from '../actions';

export const reducerFromMap = (map, initialState = null) => (...args) => {
  if (args.length > 0 && !args[0] && initialState) {
    args[0] = initialState;
  }
  const action = args[1];
  const reducer = map[action.type];
  let newState = args[0];
  if (reducer) {
    newState = reducer(...args);
  }

  return newState;
};

const reducerMap = {};


reducerMap[CALENDAR_UPDATE_FILTERS] = (state, action) => ({
  ...state,
  calendarFilters: action.payload,
});


const reducer = reducerFromMap(reducerMap);

export default reducer;
