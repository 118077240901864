import React from 'react';
import TextEllipsis from 'react-text-ellipsis';

import './style.css';
import { getPrice } from '../../libs/utils';

const ServiceItem = ({ service }) => (
  <div className="search-item-service-wrapper">
    <span className="category-title">{service.category || 'Categoria'}</span>
    <TextEllipsis
      lines={3}
      tag="p"
      ellipsisChars="..."
      tagClass="service-title"
      debounceTimeoutOnResize={200}
      useJsOnly
    >
      {service.name}
    </TextEllipsis>
    <span className="service-price">{getPrice(service.price, service.duration)}</span>
  </div>
);

export default ServiceItem;
