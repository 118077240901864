import React from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';
import { Formik } from 'formik';

import { FormControlLabel, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CustomInput from '../custom-input';
import CustomCheckbox from '../custom-checkbox';

import './styles.css';


const RequestServiceModal = ({
  visible, onClose, newServiceRequest,
}) => (
  <Modal show={visible} onHide={onClose} backdrop="static">
    <Modal.Header style={{ borderWidth: 0 }} closeButton>
      <h5>Richiedi servizio</h5>
    </Modal.Header>
    <Modal.Body className="reservation-from-modal-content">
      <Formik
        initialValues={{
          service_name: '',
          first_name: '',
          last_name: '',
          email: '',
        }}
        validate={(values) => {
          const errors = {};

          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Indirizzo mail non valido';
          }

          if (!values.privacy) {
            errors.privacy = 'Accettare trattamento sulla privacy';
          }

          return errors;
        }}
        onSubmit={(values) => {
          newServiceRequest(values);
          onClose();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">

            <Grid container>

              <Grid item xs={12}>
                <div className="form-input-wrapper">
                  <CustomInput
                    required
                    onChange={handleChange('service_name')}
                    onBlur={handleBlur('service_name')}
                    value={values.service_name}
                    id="service_name"
                    label="Nome servizio"
                    variant="outlined"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="form-input-wrapper">
                  <CustomInput
                    required
                    onChange={handleChange('first_name')}
                    onBlur={handleBlur('first_name')}
                    value={values.first_name}
                    id="first_name"
                    label="Nome"
                    variant="outlined"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="form-input-wrapper">
                  <CustomInput
                    required
                    onChange={handleChange('last_name')}
                    onBlur={handleBlur('last_name')}
                    value={values.last_name}
                    id="last_name"
                    label="Cognome"
                    variant="outlined"
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="form-input-wrapper">
                  <CustomInput
                    required
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    id="email"
                    label="Email"
                    variant="outlined"
                  />
                </div>
              </Grid>

              <div style={{ display: 'table' }}>
                <CustomCheckbox style={{ display: 'table-cell' }} id="privacy" checked={values.privacy} onChange={handleChange('privacy')} />
                <label htmlFor="privacy" style={{ display: 'table-call', verticalAlign: 'middle' }}>
                        Dichiaro di aver letto ed approvato la
                  {' '}
                  <a target="_blank" rel="noopener noreferrer" href="/docs/privacy_link.pdf">privacy policy</a>
                </label>
              </div>

            </Grid>

            <div className="errors-wrapper">
              {errors.email && touched.email && errors.email}
              {errors.privacy}
            </div>
            <div style={{ textAlign: 'center', marginBottom: 20 }}>
              <Button style={{ marginRight: 15 }} onClick={onClose} variant="yellow">Annulla</Button>
              <Button style={{ marginLeft: 15 }} type="submit" onClick={handleSubmit} variant="green">Invia</Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal.Body>
  </Modal>
);

export default withRouter(RequestServiceModal);
