import React from 'react';
import colors from '../../res/colors';

export default ({ level }) => (
  <div style={{
    backgroundColor: colors.primary.normal, margin: 20, borderRadius: 12, overflow: 'hidden',
  }}
  >
    <div style={{ backgroundColor: colors.secondary.normal, width: `${level}%`, height: 20 }} />
  </div>
);
