import React, { useState, useEffect } from 'react';
import TextEllipsis from 'react-text-ellipsis';
import { Link } from 'react-router-dom';
import { Container, Carousel, Button } from 'react-bootstrap';
import { Grid, Box } from '@material-ui/core';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './styles.css';
import colors from '../../res/colors';
import { getMediaUrl, getPrice, formatKilometers } from '../../libs/utils';
import images from '../../res/images';
import Review from '../../components/review';
import ServiceItem from '../../components/service-item';
import Loader from '../../components/loader';
import SearchOverviewBar from '../../components/search-overview-bar';
import CustomTitle from '../../components/custom-title';


export default ({
  user, searchResults, loading, search,
}) => {
  const [order, setOrder] = useState('price');
  const [sortedSearchResults, setSortedSearchResults] = useState();


  useEffect(() => {
    if (order && searchResults && searchResults.length > 0) {
      const localItems = [...searchResults];

      const orderParam = (() => {
        if (order === 'price') return { value: 'avg_price', type: 'DESC' };
        if (order === 'reviews') return { value: 'score', type: 'ASC' };
        if (order === 'distance') return { value: 'distance', type: 'DESC' };

        return { value: '', type: 'ASC' };
      })();

      localItems.sort((a, b) => {
        const val = orderParam.value;

        if (orderParam.type === 'DESC') {
          if (a[val] < b[val]) {
            return -1;
          }

          if (a[val] > b[val]) {
            return 1;
          }
        } else {
          if (a[val] > b[val]) {
            return -1;
          }

          if (a[val] < b[val]) {
            return 1;
          }
        }

        return 0;
      });


      setSortedSearchResults(localItems);
    }
  }, [searchResults, order]);


  return (
    <div className="search-wrapper">
      <Container className="categories-explore">
        <CustomTitle title="Risultati ricerca" />
        <SearchOverviewBar initialOrder={order} onOrderChange={(o) => setOrder(o)} />
        <Grid container>

          {searchResults === undefined ? (
            <div style={{
              width: '100%', display: 'block',
            }}
            >
              <Loader />
            </div>
          ) : null}

          {searchResults && searchResults.length === 0 ? (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <h5>
                La ricerca non ha prodotto risultati.
                <br />
                Contatta il callcenter
              </h5>
            </div>
          ) : (
            <>
              {sortedSearchResults && sortedSearchResults.map((item) => {
                const service = search !== undefined && search.service_id ? item.services.find((s) => s.service_id === search.service_id) : undefined;

                return (
                  <Grid key={item.id} className="search-item" item xs={12}>
                    <Box boxShadow={3} key={item.id} className="search-item">
                      <div className="search-top-bar">
                        <img src={getMediaUrl(item.media.profile)} alt="Search item" />
                        <div className="name-title-wrapper">
                          <h5 className="search-item-title">{item.info.rag_soc}</h5>
                          <span className="level-text">{item.level}</span>
                        </div>
                        <div className="separator" />
                        {item.score && (
                        <div className="other-info-wrapper">
                          <h6>Media recensioni</h6>
                          <span className="other-info-value">{item.score}</span>
                        </div>
                        )}

                        {service !== undefined ? (
                          <div className="other-info-wrapper">
                            <div>
                              <h6>
                                Prezzo del servizio
                              </h6>
                              <span>{`${(parseFloat(service.price) / 100).toFixed(2)}€/hr` }</span>
                            </div>
                            <div>
                              <h6>
                                Descrizione del servizio
                              </h6>
                              <p>
                                {service.description}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            {item.avg_price && (
                            <div className="other-info-wrapper">
                              <h6>Prezzo medio</h6>
                              <span className="other-info-value">{`${(parseFloat(item.avg_price) / 100).toFixed(2)}€` }</span>
                            </div>
                            )}
                          </>
                        )}

                      </div>
                      {item.info && item.info.description && (
                      <div className="search-item-description-wrapper">
                        <h6>Descrizione</h6>
                        <p className="search-item-description">{item.info.description}</p>
                      </div>
                      )}

                      <Grid container>
                        {item.services && item.services.length > 0 && (
                        <Grid key={`services_${item.id}`} className="search-item-services-wrapper" item xs={12} md={6}>
                          <h6>Servizi offerti</h6>
                          <div className="horizontal-scroll">
                            {item.services.slice(0, 7).map((service) => (
                              <ServiceItem key={`service_${service.id}`} service={service} />
                            ))}
                            {item.services.length > 7 && (
                            <div className="search-item-service-wrapper">
                              <span className="category-title">{' '}</span>
                              <p className="service-title">E altro...</p>
                            </div>
                            )}
                          </div>
                        </Grid>
                        )}
                        {item.reviews && item.reviews.length > 0 && (
                        <Grid key={`reviews_${item.id}`} className="search-item-reviews-wrapper" item xs={12} md={6}>
                          <h6>Recensioni</h6>
                          <ReviewsSlider items={item.reviews} />
                        </Grid>
                        )}
                      </Grid>
                      <Grid key={item.id} className="search-item-reviews-wrapper" item xs={12}>
                        <h6>Distanza</h6>
                        <p>
                          {`${formatKilometers(item.distance)} km`}
                        </p>
                      </Grid>

                      <div className="reservation-button-wrapper">
                        <Link to={`/fornitore/${item.id}`} className="nav-link">
                          <Button className="reservation-button" variant="yellow">Prenota</Button>
                        </Link>
                      </div>

                    </Box>
                  </Grid>
                );
              })}
            </>
          )}

        </Grid>
      </Container>
    </div>
  );
};


const ReviewsSlider = ({ items }) => (
  <div className="reviews">
    <Carousel>
      {items.map((review) => (
        <Carousel.Item>
          <Review review={review} />
        </Carousel.Item>
      ))}
    </Carousel>
  </div>

);
