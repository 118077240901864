import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

import colors from '../../res/colors';

const CustomCheckbox = withStyles({
  root: {
    color: colors.primary.normal,
    '&$checked': {
      color: colors.secondary.normal,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;
