import React, { useCallback } from 'react';
import {
  Modal, Button,
} from 'react-bootstrap';

import './styles.css';

import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CustomInput from '../custom-input';

const ReservationClosing = ({
  visible, onClose, reservation, changeReservationStatus,
}) => {
  const handleConfirm = useCallback(async (token) => {
    changeReservationStatus({
      status: 'completed',
      message: '',
      token,
      reservationId: reservation.id,
    }, () => {
      if (onClose) {
        onClose();
      }
    });
  }, [reservation, onClose]);

  if (reservation === undefined) return null;

  const ValidationSchema = Yup.object().shape({
    token: Yup.string().required('Campo obbligatorio')
      .min(2, 'Codice non valido'),
  });

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        <h5 style={{ textAlign: 'center' }}>Chiusura prestazione</h5>
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        <div className="reservation-closing-wrapper">

          <Formik
            validateOnChange
            initialValues={{ token: '' }}
            validationSchema={ValidationSchema}
            onSubmit={(values) => {
              handleConfirm(values.token);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container>
                  <Grid item xs={12}>
                    <div className="form-input-wrapper">
                      <p>
                        È necessario che il cliente o chi per lui fornisca il
                        codice di conferma della prenotazione per prova di
                        conclusione della prestazione
                      </p>
                      <CustomInput
                        required
                        onChange={handleChange('token')}
                        onBlur={handleBlur('token')}
                        value={values.token}
                        id="token"
                        label="Codice"
                        variant="outlined"
                        error={touched.token && errors.token}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'right' }}>
                  <Button disabled={Object.keys(errors).length > 0} type="submit" onClick={handleSubmit} variant="yellow">Conferma</Button>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withRouter(ReservationClosing);
