import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { Modal } from 'react-bootstrap';
import UnloggedReservationStepFrom from './UnloggedReservationStepFrom';
import LoggedReservationStepFrom from './LoggedReservationStepFrom';

import {
  addSearchOption, saveReservation, sendGuestReservationRequest, sendReservationRequest, fetchServices, fetchAddresses, addAddresses, flushParam, fetchPaymentMethods, addReservationOption,
} from '../../store/actions';

const PreReservationStepForm = ({
  visible,
  onClose,
  user,
  search,
  services,
  supplier,
  saveReservation,
  sendGuestReservationRequest,
  sendReservationRequest,
  fetchServices,
  addSearchOption,
  addresses,
  addAddresses,
  savedSearch,
  flushParam,
  fetchPaymentMethods,
  paymentMethods,
  addPaymentMethods,
}) => {
  useEffect(() => {
    if (services === undefined) {
      fetchServices();
    }
  }, []);

  useEffect(() => {
    if (user && savedSearch && addresses) {
      Object.entries(savedSearch).forEach((data) => {
        if (data[0] === 'address') {
          const address = data[1];
          const remoteAddress = addresses.find((a) => a.line1 === address.address);

          if (remoteAddress === undefined) {
            addAddresses({
              address: address.address,
              city: address.city,
              postalCode: address.postalCode,
              coordinates: {
                lat: address.coordinates.lat,
                lng: address.coordinates.lng,
              },
            });
          }
        } else {
          addSearchOption({
            key: data[0],
            data: data[1],
          });
        }
      });

      flushParam('savedSearch');
      flushParam('savedSupplier');
    }
  }, [user, addresses]);

  return (
    <Modal show={visible} onHide={onClose} backdrop="static">
      {user
        ? (
          <LoggedReservationStepFrom
            user={user}
            addSearchOption={addSearchOption}
            onClose={onClose}
            search={search}
            services={services}
            supplier={supplier}
            saveReservation={saveReservation}
            sendReservationRequest={sendReservationRequest}
            addresses={addresses}
            addAddresses={addAddresses}
            savedSearch={savedSearch}
            paymentMethods={paymentMethods}
            fetchPaymentMethods={fetchPaymentMethods}
            addReservationOption={addReservationOption}
            addPaymentMethods={addPaymentMethods}
          />
        )
        : (
          <UnloggedReservationStepFrom
            addSearchOption={addSearchOption}
            onClose={onClose}
            search={search}
            services={services}
            supplier={supplier}
            saveReservation={saveReservation}
            sendGuestReservationRequest={sendGuestReservationRequest}
          />
        )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  services: state.services,
  serviceCategories: state.serviceCategories,
  supplier: state.supplier,
  search: state.search,
  addresses: state.addresses,
  savedSupplier: state.savedSupplier,
  savedSearch: state.savedSearch,
  paymentMethods: state.paymentMethods,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addSearchOption,
  addReservationOption,
  saveReservation,
  sendGuestReservationRequest,
  sendReservationRequest,
  fetchServices,
  fetchAddresses,
  fetchPaymentMethods,
  addAddresses,
  flushParam,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreReservationStepForm);
