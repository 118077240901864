import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopBar from './TopBar';

import {
  fetchUserSelf, fetchUserReservationRequests, fetchUserReservations, fetchAddresses,
} from '../../store/actions';


const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUserSelf,
  fetchUserReservationRequests,
  fetchUserReservations,
  fetchAddresses,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopBar);
