import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

const ConfirmEmail = ({ user, history }) => {
  useEffect(() => {
    if (user !== undefined) {
      history.push('/dashboard');
    }
  }, [user]);

  return (
    <Container style={{ marginTop: 30 }}>
      <h3>Registrazione</h3>
      <p>
        Conferma la tua email cliccando sul link che ti abbiamo inviato.
      </p>
      <p>Dopo aver completato la procedura, accedi con le tue credenziali</p>
    </Container>
  );
};

export default withRouter(ConfirmEmail);
