export const SET_MODAL_CONFIG = 'modal/SET_CONFIG';
export const SET_NESTED_MODAL_CONFIG = 'nestedModal/SET_CONFIG';
export const FLUSH_ERRORS = 'errors/FLUSH';
export const FLUSH_MESSAGE = 'message/FLUSH';
export const FLUSH_PARAM = 'common/FLUSH_PARAM';
export const SHOW_TOAST = 'toast/SHOW';
export const NAVIGATE_TO = 'navigation/NAVIGATE';
export const THROW_ERROR = 'error/THROW';
export const SHOW_MESSAGE = 'message/SHOW';
export const FETCH_NOTIFICATIONS = 'notifications/FETCH';
export const MARK_NOTIFICATIONS_AS_READ = 'notifications/MARK_AS_READ';
export const CALENDAR_UPDATE_FILTERS = 'calendar/UPDATE_FILTERS';


export const flushErrors = () => ({
  type: FLUSH_ERRORS,
});

export const flushMessage = () => ({
  type: FLUSH_MESSAGE,
});

export const flushParam = (payload) => ({
  type: FLUSH_PARAM, payload,
});

export const showToast = (text) => ({
  type: SHOW_TOAST, text,
});

export const navigateTo = (payload) => ({
  type: NAVIGATE_TO, payload,
});


export const throwError = (payload) => ({
  type: THROW_ERROR, payload,
});

export const showMessage = (payload) => ({
  type: SHOW_MESSAGE, payload,
});

export const fetchNotifications = () => ({
  type: FETCH_NOTIFICATIONS,
});

export const markNotificationsAsRead = () => ({
  type: MARK_NOTIFICATIONS_AS_READ,
});

export const updateCalendarFilters = (payload) => ({
  type: CALENDAR_UPDATE_FILTERS, payload,
});
