import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/loader';
import ReservationRow from '../../../components/reservation-row';
import CustomInput from '../../../components/custom-input';

import './style.css';
import ReservationDetail from '../../../components/reservation-detail';
import { getStatus } from '../../../libs/utils';
import ReservationClosing from '../../../components/reservation-closing';

const Reservations = ({
  user, reservations, fetchUserReservations,
}) => {
  const [filteredReservations, setFiteredReservations] = useState();
  const [nextReservations, setNextReservations] = useState([]);
  const [pastReservations, setPastReservations] = useState([]);
  const [showReservationDetail, setShowReservationDetail] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState();
  // const [selectedNeedy, setSelectedNeedy] = useState(user.data && user.data.needy && user.data.needy[0]);
  const [filters, setFilters] = useState({});
  const [showReservationClosing, setShowReservationClosing] = useState(false);

  useEffect(() => {
    fetchUserReservations();
  }, []);

  useEffect(() => {
    if (selectedReservation) {
      console.log('showing reservation details');
      setShowReservationDetail(true);
    } else {
      setShowReservationDetail(false);
    }
  }, [selectedReservation]);

  // useEffect(() => {
  //   if (reservations) {
  //     const next = [];
  //     const past = [];

  //     reservations.forEach((reservation) => {
  //       if (filters.selectedNeedy !== undefined && reservation.reservation_request.needy.id !== filters.selectedNeedy.id) return;

  //       const date = moment(reservation.reservation_request.end_date_time);

  //       if (date.isBefore(moment())) {
  //         past.push(reservation);
  //       } else {
  //         next.push(reservation);
  //       }

  //       setNextReservations(next);
  //       setPastReservations(past);
  //     });
  //   }
  // }, [reservations, filters]);

  // const renderNeedySelection = useCallback(() => {
  //   if ('needy' in user.data) {
  //     return (
  //       <div className="supplier-calendar-date-selection" style={{ display: 'flex' }}>
  //         <Box boxShadow={2} style={{ borderRadius: 4 }}>
  //           <CustomDropdown
  //             items={user.data.needy}
  //             selected={selectedNeedy}
  //             keyExtractor={(item) => item.id}
  //             renderItem={(item) => item.full_name}
  //             onSelect={(item) => setFilters({ ...filters, selectedNeedy: item })}
  //           />
  //         </Box>
  //       </div>
  //     );
  //   }

  //   return null;
  // }, [user, selectedNeedy]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 && reservations !== undefined) {
      setFiteredReservations(reservations.filter((e) => {
        if (filters.selectedNeedy && filters.selectedNeedy !== 'Tutti') {
          if ((e.reservation_request !== undefined && e.reservation_request.needy.full_name !== filters.selectedNeedy) || (e.needy !== undefined && e.needy.full_name !== filters.selectedNeedy)) { return false; }
        }

        if (filters.selectedStatus) {
          const status = getStatus(e.reservation_request);
          if (filters.selectedStatus === 'Concluso' && status.value !== 'completed') return false;
          if (filters.selectedStatus === 'Confermato' && status.value !== 'accepted') return false;
          if (filters.selectedStatus === 'In corso' && status.value !== 'in-progress') return false;
          if (filters.selectedStatus === 'Annullato' && status.value !== 'canceled') return false;
        }

        return true;
      }));
    }
  }, [reservations, filters]);

  const renderFilters = () => (
    <div className="filters-wrapper">
      <h6>Filtri</h6>

      <Grid container>

        {'needy' in user.data && (
          <Grid item={true} xs={12} md={4}>
            <div className="form-input-wrapper">
              <CustomInput
                type="select"
                title="Seleziona l'anziano"
                options={['Tutti', ...user.data.needy.map((g) => g.full_name)]}
                onChange={(item) => setFilters({ ...filters, selectedNeedy: item })}
                value={filters.selectedNeedy || 'Tutti'}
                id="needy"
                label="Anziano"
                variant="outlined"
              />
            </div>
          </Grid>
        )}

        <Grid item={true} xs={12} md={4}>
          <div className="form-input-wrapper">
            <CustomInput
              type="select"
              title="Seleziona lo stato degli eventi che vuoi vedere"
              options={['Tutti', 'Confermato', 'Concluso', 'In corso', 'Annullato']}
              onChange={(item) => setFilters({ ...filters, selectedStatus: item })}
              value={filters.selectedStatus || 'Tutti'}
              id="status"
              label="Stato evento"
              variant="outlined"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );

  if (reservations === undefined) return <Loader />;

  return reservations.length > 0 ? (
    <div className="reservation-wrapper">
      <h4>Prestazioni</h4>

      {renderFilters()}

      <h5>Prestazioni</h5>
      {(filteredReservations !== undefined ? filteredReservations : reservations).map((reservation) => (
        <ReservationRow
          reservation={reservation}
          onClick={() => setSelectedReservation(reservation)}
        />
      ))}

      <ReservationDetail
        onClose={() => {
          setShowReservationDetail(false);
          if (showReservationClosing === false) {
            setSelectedReservation(undefined);
          }
        }}
        onUpdate={() => {
          setShowReservationDetail(false);
          setSelectedReservation(undefined);
        }}
        onReservationCloseIntent={() => {
          console.log('aaaaaaaa');
          setShowReservationDetail(false);
          setShowReservationClosing(true);
        }}
        visible={showReservationDetail}
        reservation={selectedReservation}
      />

      <ReservationClosing
        onClose={() => {
          setShowReservationClosing(false);
          setSelectedReservation(undefined);
        }}
        visible={showReservationClosing}
        reservation={selectedReservation}
      />

    </div>
  ) : (
    <div className="reservation-wrapper">
      <h4>Prestazioni</h4>
      {user.data.type === 'supplier' ? (
        <p>Non hai ancora nessuna prestazione da svolgere</p>
      ) : (
        <p>Non hai ancora mandato nessuna richiesta, oppure nessuna delle richieste che hai mandato è stata accettata</p>
      )}
    </div>
  );
};
export default withRouter(Reservations);
