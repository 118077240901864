import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@material-ui/icons/Place';

import { Box } from '@material-ui/core';
import colors from '../../res/colors';


const AnyReactComponent = ({ text }) => (
  <div>
    <PlaceIcon style={{ fontSize: 40, color: colors.secondary.normal }}>filled</PlaceIcon>
  </div>
);
const SupplierMap = ({ lat, lng }) => {
  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat,
    lng,
  });

  useEffect(() => {
    setCenter({
      lat,
      lng,
    });
  }, [lat, lng]);


  return (
  // Important! Always set the container height explicitly
    <div className="supplier-map-wrapper">
      <Box boxShadow={2} style={{ height: 300, width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyA6ELucVPY7jNHkXfgF3QHBaSpKpSe3Hlg' }}
          center={center}
          defaultZoom={zoom}
          options={{
            zoomControl: false,
            minZoom: 16,
            maxZoom: 16,
          }}
        >
          <AnyReactComponent
            lat={lat}
            lng={lng}
          />
        </GoogleMapReact>
      </Box>
    </div>
  );
};

export default SupplierMap;
