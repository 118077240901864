import React from 'react';
import TextEllipsis from 'react-text-ellipsis';
import { Box } from '@material-ui/core';

import './style.css';
import { getMediaUrl, getRandomString } from '../../libs/utils';
import images from '../../res/images';

const Review = ({ review }) => (
  <div className="review-item-wrapper">
    <Box boxShadow={2} className="review-item">
      <img src={getMediaUrl(review.done_by.media.profile)} className="review-user-photo" alt="user" />
      <div className="review-item-content">
        <h6>{review.done_by.full_name}</h6>
        <TextEllipsis
          lines={4}
          tag="p"
          ellipsisChars="..."
          tagClass="service-title"
          debounceTimeoutOnResize={200}
          useJsOnly
        >
          {review.text}
        </TextEllipsis>
      </div>
      <div className="stars-wrapper">
        {[...Array(review.score).keys()].map((item) => <Star key={item} keyfilled />)}
        {[...Array(5 - review.score).keys()].map((item) => <Star key={item} />)}
      </div>

    </Box>
  </div>
);

const Star = ({ filled }) => {
  if (filled) {
    return <img alt="star" className="star filled" src={images.icons.star} />;
  }

  return <img alt="star" className="star" src={images.icons.stargray} />;
};


export default Review;
