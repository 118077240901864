import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Search from './Search';

const mapStateToProps = (state) => ({
  user: state.user,
  searchResults: state.searchResults,
  loading: state.loading,
  search: state.search,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
