import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AccountSettings from './AccountSettings';

import { fetchGenders, updateUserData, updateUserProfilePhoto } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  genders: state.genders,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateUserData,
  updateUserProfilePhoto,
  fetchGenders,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);
