import { reducerFromMap } from './commons.reducers';
import {
  USER_LOGIN, USER_FETCH_SELF, USER_LOGOUT, USER_FETCH_RESERVATIONS, USER_FETCH_RESERVATION_REQUESTS, USER_SET_REGISTRATION_DATA, SET_MODAL_CONFIG, SET_NESTED_MODAL_CONFIG, FETCH_GENDERS, USER_REGISTER, FLUSH_ERRORS, FETCH_NEEDY, FETCH_ADDRESSES, FETCH_PAYMENT_METHODS, ADD_NEEDY, FLUSH_PARAM, SAVE_RESERVATION_REQUEST, SHOW_TOAST, NAVIGATE_TO, SEND_GUEST_RESERVATION_REQUEST, ADD_ADDRESSES, DELETE_ADDRESSES, THROW_ERROR, SHOW_MESSAGE, ADD_PAYMENT_METHODS,
} from '../actions';

const reducerMap = {};

reducerMap[USER_LOGIN] = (state, action) => ({
  ...state,
  loading: true,
  user: undefined,
});

reducerMap[USER_LOGOUT] = (state, action) => ({
  ...state,
  loading: false,
  user: undefined,
  addresses: undefined,
  reservations: undefined,
  reservationRequests: undefined,
  paymentMethods: undefined,
});

reducerMap[`${USER_LOGIN}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  user: {
    ...state.user,
    ...action.payload.data,
  },
});

reducerMap.UNAUTHENTICAED_ERROR = (state) => ({
  ...state,
  loading: false,
  user: undefined,
});

reducerMap[`${USER_LOGIN}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  user: undefined,
  error: {
    ...action.payload.data,
  },
});

reducerMap[`${USER_FETCH_SELF}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  user: {
    ...state.user,
    ...action.payload.data,
  },
});

reducerMap[`${SEND_GUEST_RESERVATION_REQUEST}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  user: undefined,
  error: {
    ...action.payload.data,
  },
});

reducerMap[USER_FETCH_RESERVATIONS] = (state, action) => ({
  ...state,
  loading: true,
  reservations: undefined,
});

reducerMap[`${USER_FETCH_RESERVATIONS}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  reservations: action.payload.data.data.sort((a, b) => new Date(b.reservation_request.date) - new Date(a.reservation_request.date)),
});

reducerMap[`${USER_FETCH_RESERVATIONS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  reservation: undefined,
  error: {
    ...action.payload.data,
  },
});

reducerMap[USER_FETCH_RESERVATION_REQUESTS] = (state, action) => ({
  ...state,
  loading: true,
  reservationRequests: undefined,
});

reducerMap[`${USER_FETCH_RESERVATION_REQUESTS}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  reservationRequests: action.payload.data.data,
});

reducerMap[`${USER_FETCH_RESERVATION_REQUESTS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  reservationRequests: undefined,
  error: {
    ...action.payload.data,
  },
});

reducerMap[USER_SET_REGISTRATION_DATA] = (state, action) => ({
  ...state,
  registrationData: {
    ...state.registrationData,
    ...action.payload,
  },
});

reducerMap[SET_MODAL_CONFIG] = (state, action) => ({
  ...state,
  modal: {
    ...action.payload,
  },
});

reducerMap[SET_NESTED_MODAL_CONFIG] = (state, action) => ({
  ...state,
  nestedModal: {
    ...action.payload,
  },
});

reducerMap[FETCH_GENDERS] = (state) => ({
  ...state,
  loading: true,
  genders: undefined,
});

reducerMap[`${FETCH_GENDERS}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  genders: action.payload.data.data,
});

reducerMap[`${FETCH_GENDERS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[USER_REGISTER] = (state) => ({
  ...state,
  loading: true,
  registration: undefined,
});

reducerMap[`${USER_REGISTER}_SUCCESS`] = (state) => ({
  ...state,
  loading: false,
});

reducerMap[`${USER_REGISTER}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[FLUSH_ERRORS] = (state) => ({
  ...state,
  error: undefined,
});

reducerMap[FETCH_NEEDY] = (state) => ({
  ...state,
  loading: true,
  needy: undefined,
});

reducerMap[`${FETCH_NEEDY}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  needy: action.payload.data.data,
});

reducerMap[`${FETCH_NEEDY}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[FETCH_ADDRESSES] = (state) => ({
  ...state,
  loading: true,
  addresses: undefined,
});

reducerMap[`${FETCH_ADDRESSES}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  addresses: action.payload.data.data,
});

reducerMap[`${FETCH_ADDRESSES}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[FETCH_PAYMENT_METHODS] = (state) => ({
  ...state,
  loading: true,
  paymentMethods: undefined,
});

reducerMap[`${FETCH_PAYMENT_METHODS}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  paymentMethods: action.payload.data.data,
});

reducerMap[`${FETCH_PAYMENT_METHODS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[ADD_NEEDY] = (state) => ({
  ...state,
  loading: true,
});

reducerMap[`${ADD_NEEDY}_SUCCESS`] = (state, action) => ({
  ...state,
  loading: false,
  addNeedy: action.payload.data.data,
});

reducerMap[`${ADD_NEEDY}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[FLUSH_PARAM] = (state, action) => ({
  ...state,
  [action.payload]: undefined,
});

reducerMap[SAVE_RESERVATION_REQUEST] = (state) => ({
  ...state,
  savedSearch: state.search,
  savedSupplier: state.supplier,
});

reducerMap[FLUSH_PARAM] = (state, action) => ({
  ...state,
  [action.payload]: undefined,
});

reducerMap[SHOW_TOAST] = (state, action) => ({
  ...state,
  toast: action.text,
});

reducerMap[NAVIGATE_TO] = (state, action) => ({
  ...state,
  navigateTo: action.payload,
});

reducerMap.MAIN_LOADING_FINISHED = (state, action) => ({
  ...state,
  mainLoading: false,
});

reducerMap[`${ADD_ADDRESSES}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[`${DELETE_ADDRESSES}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

reducerMap[THROW_ERROR] = (state, action) => ({
  ...state,
  error: {
    ...action.payload,
  },
});

reducerMap[SHOW_MESSAGE] = (state, action) => ({
  ...state,
  message: action.payload,
});

reducerMap[`${ADD_PAYMENT_METHODS}_FAIL`] = (state, action) => ({
  ...state,
  loading: false,
  error: {
    ...action.payload.data,
  },
});

const reducer = reducerFromMap(reducerMap);

export default reducer;
