import React, { useEffect } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';


import './style.css';

const NotificationBell = ({ notifications = [], fetchNotifications }) => (
  <div className="notification-bell-wrapper">
    <NotificationsIcon style={{ color: '#E6E6E6', fontSize: 35 }}>filled</NotificationsIcon>
    {notifications.length > 0 && (
      <div className="notification-counter">
        <span>{notifications.length}</span>
      </div>
    )}
  </div>
);

export default NotificationBell;
