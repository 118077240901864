import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import {
  InputGroup, FormControl, Button, Container,
} from 'react-bootstrap';
import { Box, Hidden, useMediaQuery } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import images from '../../res/images';

import HomeSearch from '../../components/home-search';
import HomeServiceSelectionModal from '../../components/home-service-selection-modal';

import { getMediaUrl } from '../../libs/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  topImageWrapper: {
    textAlign: 'right',
  },
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchBox: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  searchInput: {
    borderBottom: 'none',
  },

  [theme.breakpoints.only('sm')]: {

  },

  [theme.breakpoints.only('xs')]: {
    searchWrapper: {
      padding: 20,
    },
  },

}));

const UnloggedMain = ({
  services, serviceCategories, performSearch, history, flushParam, search,
}) => {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (search) {
      flushParam('search');
    }
  }, []);

  return (
    <div className={classes.root}>
      <Grid container style={{ height: 500 }}>
        <Grid className={classes.searchWrapper} item xs={12} md={6} style={{ height: 500 }}>
          <div style={{ maxWidth: 500 }}>
            <HomeSearch services={services} serviceCategories={serviceCategories} />
            <p className="homepage-text">
              MySerenCare è la piattaforma online che aiuta a
              {' '}
              <b style={{ color: '#E4AC04' }}>selezionare i migliori servizi per gli anziani</b>
              {' '}
              che vivono soli in casa.
              <br />
              <br />
              In modo semplice e veloce
              {' '}
              <b style={{ color: '#E4AC04' }}>permette di cercare tra un vasto elenco di assistenti esperti e affidabili</b>
              , selezionando la soluzione migliore per ogni esigenza e assicurando un supporto quotidiano alla vita di tutta la famiglia.
            </p>
          </div>
        </Grid>
        <Hidden only={['xs', 'sm']}>
          <Grid className={classes.topImageWrapper} item md={6}>
            <img className={classes.topImage} style={{ maxHeight: 500 }} src={images.elder} alt="Elder" />
          </Grid>
        </Hidden>

      </Grid>

      {serviceCategories && serviceCategories.length > 0 && (
      <div className="categories-explore-wrapper">
        <Container className="categories-explore">
          <h2 className="white-text">Esplora le categorie</h2>
          <Grid container>
            {serviceCategories.map((serviceCategory) => (
              <Grid
                onClick={() => {
                  setSelectedCategory(serviceCategory.id);
                  setShowCategoryModal(true);
                  // performSearch();
                  // history.push('/search');
                }}
                key={serviceCategory.id}
                className="service-category-wrapper"
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Link to={`/categoria/${serviceCategory.slug}`} className="nav-link">
                  <Box boxShadow={2} className="service-category" style={{ backgroundImage: `url(${getMediaUrl(serviceCategory.media.main)})` }}>
                    <div className="service-category-title-wrapper">{serviceCategory.name}</div>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      )}

      <HomeServiceSelectionModal
        visible={showCategoryModal}
        category={selectedCategory}
        onClose={() => {
          setShowCategoryModal(false);
        }}
      />

    </div>
  );
};

export default withRouter(UnloggedMain);
