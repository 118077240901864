import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addSearchOption } from '../../store/actions';
import HomeSearch from './HomeSearch';

const mapStateToProps = (state) => ({
  user: state.user,
  services: state.services,
  serviceCategories: state.serviceCategories,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addSearchOption,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeSearch);
