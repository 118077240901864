import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';

import Dashboard from '../containers/dashboard';


export default () => {
  const match = useRouteMatch();

  return (
    <Switch>

      <Route path={`${match.path}`}>
        <Dashboard />
      </Route>

    </Switch>
  );
};
