/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Modal, Button } from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import Swal from 'sweetalert2';
import CustomInput from './index';
import Loader from '../loader';
import colors from '../../res/colors';

const CustomSelectInput = ({
  id, title, error, onFocus, onChange, onBlur, showInModal = true, options, ...props
}) => {
  const [preventFocus, setPreventFocus] = useState(false);
  const [modalVisible, setModalVisibile] = useState();

  const [addressInput, setAddressInput] = useState('');
  const [address, setAddress] = useState('');

  const handleOnFocus = (e) => {
    e.preventDefault();

    if (!preventFocus) {
      setModalVisibile(true);
      setPreventFocus(true);

      if (onFocus) {
        onFocus();
      }
    } else {
      setPreventFocus(false);
    }
  };

  const handleClick = (event) => {
    event.persist();
    onChange(address);
    setModalVisibile(false);
    setTimeout(() => {
      document.activeElement.blur();
      onBlur(event);
    }, 400);
  };

  const renderAddressSelection = () => (
    <>
      <PlacesAutocomplete
        style={{ width: '100%' }}
        value={addressInput}
        onChange={setAddressInput}
            // eslint-disable-next-line no-shadow
        onSelect={async (address) => {
          setAddressInput(address);
          const data = await geocodeByAddress(address);

          try {
            const parsedAddress = {
              address: `${data[0].address_components.find((item) => item.types.includes('route')).long_name}, ${data[0].address_components.find((item) => item.types.includes('street_number')).long_name}`,
              city: data[0].address_components.find((item) => item.types.includes('locality')).long_name,
              postalCode: data[0].address_components.find((item) => item.types.includes('postal_code')).long_name,
              coordinates: {
                lat: data[0].geometry.location.lat(),
                lng: data[0].geometry.location.lng(),
              },
            };

            if (showInModal) {
              setAddress(parsedAddress);
            } else {
              onChange(parsedAddress);
            }
          } catch (err) {
            Swal.fire('Errore', 'Indirizzo non valido', 'error');
          }
        }}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <div className="address-selection-wrapper">
            <p>Es. Via Roma, 1</p>
            <CustomInput
              type="text"
              autoFocus
              label="Indirizzo"
              variant="outlined"
              color={colors.primary.normal}
              {...getInputProps({
                placeholder: 'Cerca indirizzo ...',
                className: 'location-search-input',
              })}
            />

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                  // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>


    </>
  );

  if (!showInModal) {
    return renderAddressSelection();
  }


  return (
    <div className="custom-input-wrapper">
      <TextField {...props} onFocus={handleOnFocus} />
      {error !== undefined && <label className="error-label" htmlFor={id}>{error}</label>}
      <Modal show={modalVisible} onHide={() => setModalVisibile(false)} backdrop="static">
        <Modal.Header style={{ borderWidth: 0 }} closeButton>
          <h5>{title}</h5>
        </Modal.Header>
        <Modal.Body className="reservation-from-modal-content">
          {renderAddressSelection()}

          <div style={{ textAlign: 'center' }}>
            <Button style={{ marginTop: 15 }} onClick={handleClick} disabled={address === ''} variant="yellow">Conferma</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomSelectInput;
