import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AddAddressModal from './AddAddressModal';

import { throwError, addAddresses } from '../../../../../store/actions';

const mapStateToProps = (state) => ({});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addAddresses,
  throwError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAddressModal);
