/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';


import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { consts } from './res';
import colors from './res/colors';
const history = createBrowserHistory();


const theme = createTheme({
  palette: {
    primary: {
      main: colors.secondary.normal,
    },
    secondary: {
      main: colors.secondary.normal,
    },
  },
});


const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

if (path) {
  history.replace(path);
}


ReactDOM.render((
  <StripeProvider apiKey={consts.stripe_pk}>
    <ThemeProvider theme={theme}>
      <Provider store={store}><App /></Provider>
    </ThemeProvider>
  </StripeProvider>
), document.getElementById('root'));

serviceWorker.unregister();
