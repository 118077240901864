import React, { useEffect } from 'react';
import Swal from 'sweetalert2';


const ErrorHandler = ({
  error, message, flushErrors, flushMessage,
}) => {
  useEffect(() => {
    if (error) {
      if (error.errors) {
        Swal.fire(
          'Errore',
          error.errors
            .map((err) => err[Object.keys(err)[0]])
            .join('\n'),
          'error',
        )
          .then(() => flushErrors());
      } else {
        let errorMessage =  error.message == "The user credentials were incorrect."  ? "Le credenziali sono sbagliate" :  error.message;
        Swal
          .fire('Errore', errorMessage, 'error')
          .then(() => flushErrors());
      }
    }
  }, [error]);

  useEffect(() => {
    if (message) {
      Swal
        .fire('Ok!', message, 'success')
        .then(() => flushMessage());
    }
  }, [message]);

  return null;
};

export default ErrorHandler;
