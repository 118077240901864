import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Availability from './Availability';

import { updateTimetable } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.loading,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateTimetable,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Availability);
