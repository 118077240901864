import React from 'react';
import Loader from 'react-loader-spinner';

import colors from '../../res/colors';
import './style.css';


const CustomLoader = ({ size, noMargin }) => (
  <div className="loader-wrapper" style={noMargin ? { margin: 0 } : {}}>
    <Loader
      type="Oval"
      color={colors.secondary.normal}
      height={size || 100}
      width={size || 100}
    />
  </div>
);

export default CustomLoader;
