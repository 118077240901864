import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import ReservationRow from './ReservationRow';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReservationRow);
