import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import UnloggedMain from './UnloggedMain';
import LoggedMain from './LoggedMain';
import './Main.css';

const Main = ({
  user,
  services,
  serviceCategories,
  fetchCategoriesServices,
  fetchServices,
  performSearch,
  history,
  flushParam,
  search,
}) => {
  useEffect(() => {
    fetchCategoriesServices();
    fetchServices();
  }, []);

  if (user) {
    if (user.data !== undefined && user.data.type === 'supplier') {
      history.push('/dashboard');
    }

    return (
      <LoggedMain
        performSearch={performSearch}
        services={services}
        serviceCategories={serviceCategories}
        flushParam={flushParam}
        search={search}
      />
    );
  }

  return (
    <UnloggedMain
      services={services}
      performSearch={performSearch}
      serviceCategories={serviceCategories}
      flushParam={flushParam}
      search={search}
    />
  );
};

export default withRouter(Main);
