/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal, Button as BootstrapButton, Dropdown,
} from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';


import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import {
  withStyles,
} from '@material-ui/core/styles';

import it from 'date-fns/locale/it';
import moment from 'moment';
import AddCircleIcon from '@material-ui/icons/AddCircle';


import './styles.css';

import { withRouter } from 'react-router-dom';
import CompletationBar from '../completation-bar';
import colors from '../../res/colors';
import CustomCheckbox from '../custom-checkbox';
import { getFullAddress } from '../../libs/utils';
import AddressSelectionContent from './AddressSelectionContent';
import Loader from '../loader';
import AddNeedyModal from '../add-needy-modal';

registerLocale('es', it);


const stepsData = [
  {
    title: 'Quando hai bisogno del servizio?',
  },
  {
    title: 'Dove vuoi che sia svolto il servizio?',
  },
  {
    title: 'Selezionare l\'anziano per cui si vuole prenotare',
  },
];

const CustomInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.secondary.normal,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.secondary.normal,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.primary.normal,
      },
      '&:hover fieldset': {
        borderColor: colors.secondary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.secondary.normal,
      },
    },
  },
})(TextField);


const LoggedPreReservationStepForm = ({
  history, addSearchOption, performSearch, user, addresses, loading,
}) => {
  const [step, setStep] = useState(1);
  const [date, setDate] = useState(moment()._d);
  const [address, setAddress] = useState('');
  const [addNeedyModalShow, setAddNeedyModalShow] = useState(false);

  const handleNext = () => {
    if (step < stepsData.length) { setStep(step + 1); }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const submit = useCallback(() => {
    addSearchOption({
      key: 'user_id',
      data: user.data.id,
    });

    addSearchOption({
      key: 'date',
      data: moment(date).format('YYYY-MM-DD'),
    });

    history.push('/ricerca');
  }, [address, date]);


  const renderHeaderContent = useCallback(() => (
    <>
      {step > 1 && (
        <div>
          <KeyboardBackspaceIcon fontSize="small" />
          <Button onClick={handleBack}>torna indietro</Button>
        </div>
      )}
      <div>
        <h5>{stepsData[step - 1].title}</h5>
      </div>
    </>
  ), [step]);


  const renderDateSelectionStepContent = useCallback(() => (
    <>
      <DatePicker
        selected={date}
        minDate={moment()._d}
        onChange={(date) => setDate(date)}
        locale="it"
        inline
      />
      <BootstrapButton onClick={handleNext} variant="yellow">Continua</BootstrapButton>
    </>
  ), [step, date]);

  const renderAddressSelectionContent = useCallback(() => (
    <AddressSelectionContent onSelected={() => { setStep(step + 1); }} />
  ), [step]);

  const renderNeedySelection = useCallback(() => {
    if (user.data.type !== 'carer') {
      submit();
    }

    if ((user.data !== undefined && user.data.needy === undefined) || loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className="settings-content-wrapper account-settings-wrapper" style={{ marginTop: 0 }}>
          {user.data.needy.length > 0 ? user.data.needy.map(
            (n) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  addSearchOption({
                    key: 'needy_id',
                    data: n.id,
                  });

                  submit();
                  // setStep(step + 1);
                }}
              >
                <h6 style={{ color: colors.secondary.normal }}>{`${n.first_name} ${n.last_name}`}</h6>
              </div>
            ),
          ) : <p>Non hai ancora aggiunto nessun anziano</p>}


        </div>

        <div style={{ textAlign: 'left', marginBottom: 20 }}>
          <div>
            <AddCircleIcon
              onClick={() => {
                setAddNeedyModalShow(true);
              }}
              style={{
                color: colors.secondary.normal, fontSize: 40, marginTop: 10, cursor: 'pointer',
              }}
            >
        filled
            </AddCircleIcon>
          </div>

        </div>

        <AddNeedyModal
          visible={addNeedyModalShow}
          onClose={() => {
            setAddNeedyModalShow(false);
          }}
        />

      </div>
    );
  }, [step, addNeedyModalShow, user, loading]);


  const renderContent = useCallback(() => {
    if (step === 1) return renderDateSelectionStepContent();
    if (step === 2) return renderAddressSelectionContent();
    if (step === 3) return renderNeedySelection();

    return null;
  }, [step, date, address, addNeedyModalShow, user, loading]);

  const renderFooter = useCallback(() => (
    <>
      <CompletationBar level={(step * 100) / stepsData.length} />
    </>
  ), [step]);

  return (
    <>
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        {renderHeaderContent()}
      </Modal.Header>
      <Modal.Body className="pre-reservation-from-modal-content">
        {renderContent()}
      </Modal.Body>
      <Modal.Footer style={{ borderWidth: 0, marginTop: 20 }}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {renderFooter()}
        </div>
      </Modal.Footer>
    </>
  );
};

export default withRouter(LoggedPreReservationStepForm);
