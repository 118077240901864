import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HomeServiceSelectionModal from './HomeServiceSelectionModal';

import {
  addSearchOption,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  services: state.services,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addSearchOption,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeServiceSelectionModal);
