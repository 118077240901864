/* eslint-disable import/prefer-default-export */
import {
  Api,
  PERFORM_SEARCH,
} from '../actions';

const api = new Api();

export const searchMiddleware = (store) => (next) => (action) => {
  const middlewareAction = {};

  middlewareAction[PERFORM_SEARCH] = async () => {
    const { search } = store.getState();
    store.dispatch(await api.call({
      url: '/search',
      method: 'post',
      data: search,
      actionType: action.type,
    }));
  };

  next(action);

  if (middlewareAction[action.type]) { middlewareAction[action.type](); }
};
