import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Container } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { withRouter, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';


import CustomInput from '../../../components/custom-input';
import './styles.css';

const PasswordReset = ({ sendUserNewPassword }) => {
  const { token } = useParams();
  const [sent, setSent] = useState(false);

  const ValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Minimo 8 caratteri')
      .required('Campo obbligatorio'),
    password_confirmation: Yup.string()
      .test('checkPasswordConfirmation', 'Non è uguale alla password inserita', function (value) {
        const password = this.resolve(Yup.ref('password'));
        return password === value;
      })
      .required('Campo obbligatorio'),
  });

  const handleConfirm = (values) => {
    const data = {
      password: values.password,
      password_confirmation: values.password_confirmation,
      token,
    };

    sendUserNewPassword(data, () => {
      setSent(true);
      Swal.fire('Ok!', 'Password modificata con successo.', 'success');
    }, ()=>{
      Swal.fire('Errore!', 'Impossibile modificare la password. \n Si prega di riprovare', 'error');
    });
  };

  return (
    <div className="password-reset-form-wrapper">
      <Container className="password-reset">
        <Container container>
          <h3>Reset della password</h3>
          <p>
            Inserisci la nuova password
          </p>
          <Formik
            validationSchema={ValidationSchema}
            validateOnChange
            initialValues={{
              password: '',
              password_confirmation: '',
            }}
            onSubmit={handleConfirm}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form style={{ marginTop: 30 }} onSubmit={handleSubmit} autoComplete="off">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div className="form-input-wrapper">
                      <CustomInput
                        required
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        error={touched.password && errors.password}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="form-input-wrapper">
                      <CustomInput
                        required
                        onChange={handleChange('password_confirmation')}
                        onBlur={handleBlur('password_confirmation')}
                        value={values.password_confirmation}
                        error={touched.password_confirmation && errors.password_confirmation}
                        id="password_confirmation"
                        type="password"
                        label="Conferma password"
                        variant="outlined"
                      />
                    </div>
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'right' }}>
                  <Button disabled={Object.keys(errors).length > 0 || sent} type="submit" onClick={handleSubmit} variant="yellow">Modifica password</Button>
                </div>
              </form>
            )}
          </Formik>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(PasswordReset);
