/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { fetchAddresses, addSearchOption, addAddresses } from '../../store/actions';
import Loader from '../loader';
import AddAddressModal from '../../containers/dashboard/settings/addresses/add-address-modal';
import colors from '../../res/colors';
import { getFullAddress } from '../../libs/utils';

const AddressSelectionContent = ({
  addresses, onSelected, fetchAddresses, addSearchOption,
}) => {
  const [addAddressModalShow, setAddAddressModalShow] = useState();

  useEffect(() => {
    if (addresses === undefined) {
      fetchAddresses();
    }
  }, []);
  if (addresses === undefined) {
    return <Loader />;
  }

  return (
    <div>
      <div className="settings-content-wrapper account-settings-wrapper" style={{ marginTop: 0, textAlign: 'left' }}>
        {addresses.length > 0 ? addresses.map(
          (address) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const data = {
                  address: address.line1,
                  city: address.city.name,
                  postalCode: address.postal_code,
                  coordinates: {
                    lat: address.geometries.latitude,
                    lng: address.geometries.longitude,
                  },
                };

                addSearchOption({
                  key: 'address',
                  data,
                });

                onSelected(address);
              }}
            >
              <h6 style={{ color: colors.secondary.normal }}>{getFullAddress(address)}</h6>
            </div>
          ),
        ) : <p>Non hai ancora aggiunto nessun indirizzo</p>}


        <AddCircleIcon
          onClick={() => setAddAddressModalShow(true)}
          style={{ color: colors.secondary.normal, fontSize: 40, marginTop: 10 }}
        >
          filled
        </AddCircleIcon>

        <AddAddressModal
          visible={addAddressModalShow}
          onClose={() => setAddAddressModalShow(false)}
          addAddress={addAddresses}
        />
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  addresses: state.addresses,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAddresses,
  addSearchOption,
  addAddresses,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressSelectionContent);
