import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal, Button as BootstrapButton, Button, Dropdown,
} from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import PayPalButton from './PaypalCheckout';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import {
  withStyles,
} from '@material-ui/core/styles';
import it from 'date-fns/locale/it';
import moment from 'moment/min/moment-with-locales';
import './styles.css';
import { withRouter } from 'react-router-dom';
import Loader from '../loader';
import AddAddressModal from '../../containers/dashboard/settings/addresses/add-address-modal';
import AddPaymentModal from '../../containers/dashboard/settings/payments/add-payment-modal';
import CompletationBar from '../completation-bar';
import colors from '../../res/colors';
import { getFullAddress, formatEventDatetime } from '../../libs/utils';
import SupplierMap from '../supplier-map';
import AddNeedyModal from '../add-needy-modal';
import CustomCheckbox from '../custom-checkbox';
import ReCaptcha from './CaptchaVerification';
import ReCAPTCHA from "react-google-recaptcha";


registerLocale('es', it);

const CustomInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.secondary.normal,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.secondary.normal,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.primary.normal,
      },
      '&:hover fieldset': {
        borderColor: colors.secondary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.secondary.normal,
      },
    },
  },
})(TextField);

const LoggedReservationStepForm = ({
  onClose, search, services, paymentMethods, supplier, history, addSearchOption, saveReservation, sendReservationRequest, user, addresses, addAddresses, fetchPaymentMethods, addReservationOption, addPaymentMethods,
}) => {
  const [step, setStep] = useState(1);
  const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [addAddressModalShow, setAddAddressModalShow] = useState(false);
  const [addNeedyModalShow, setAddNeedyModalShow] = useState(false)

  useEffect(() => {
    // if (paymentMethods === undefined) {
    //   fetchPaymentMethods();
    // }

    if (search !== undefined && search.address !== undefined) {
      setSelectedAddress(search.address);
    }
  }, []);

  useEffect(() => {
    if (user) {
      addSearchOption({
        key: 'user_id',
        data: user.data.id,
      });
    }
  }, [user]);

  const handleContinue = () => {
    if (step === 1) {
      addSearchOption({
        key: 'notes',
        data: notes,
      });

      console.log('step:' + step + '+1');

      setStep(step + 1);
    }
  };

  const renderNotesForm = useCallback(() => (
    <div>
      <CustomInput
        onChange={(event) => setNotes(event.target.value)}
        value={notes}
        id="notes"
        label="Note"
        variant="outlined"
      />
    </div>
  ), [step, notes]);

  const renderNeedySelection = useCallback(() => {
    if (user.data.type !== 'carer') {
      setStep(step + 1);
      return null;
    }

    if ((user.data.needy === undefined) || loading) {
      return <Loader />;
    }
    return (
      <div>
        <div className="settings-content-wrapper account-settings-wrapper" style={{ marginTop: 0 }}>
          {user.data.needy.length > 0 ? user.data.needy.map(
            (n) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  addSearchOption({
                    key: 'needy_id',
                    data: n.id,
                  });
                  setStep(step + 1);
                }}
              >
                <h6 style={{ color: colors.secondary.normal }}>{`${n.first_name} ${n.last_name}`}</h6>
              </div>
            ),
          ) : <p>Non hai ancora aggiunto nessun anziano</p>}

        </div>

        <div style={{ textAlign: 'left', marginBottom: 20 }}>
          <div>
            <AddCircleIcon
              onClick={() => {
                setAddNeedyModalShow(true);
              }}
              style={{
                color: colors.secondary.normal, fontSize: 40, marginTop: 10, cursor: 'pointer',
              }}
            >
              filled
            </AddCircleIcon>
          </div>

        </div>

        <AddNeedyModal
          visible={addNeedyModalShow}
          onClose={() => {
            setAddNeedyModalShow(false);
          }}
        />

      </div>
    );
  }, [step, addNeedyModalShow, user, loading]);

  const renderAddressSelectionForm = useCallback(() => {
    if (addresses === undefined) {
      return <Loader />;
    }

    return (
      <div>
        <div className="settings-content-wrapper account-settings-wrapper">
          {addresses.length > 0 ? addresses.map(
            (address) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  addSearchOption({
                    key: 'address',
                    data: {
                      address: address.line1,
                      city: address.city.name,
                      postalCode: address.postal_code,
                      geometries: {
                        latitude: address.geometries.latitude,
                        longitude: address.geometries.longitude,
                      },
                    },
                  });
                  setStep(step + 1);
                }}
              >
                <h6 style={{ color: colors.secondary.normal }}>{getFullAddress(address)}</h6>
              </div>
            ),
          ) : <p>Non hai ancora aggiunto nessun indirizzo</p>}

          <AddCircleIcon
            onClick={() => setAddAddressModalShow(true)}
            style={{ color: colors.secondary.normal, fontSize: 40, marginTop: 10 }}
          >
            filled
          </AddCircleIcon>

          <AddAddressModal
            visible={addAddressModalShow}
            onClose={() => setAddAddressModalShow(false)}
            addAddress={addAddresses}
          />
        </div>
      </div>
    );
  }, [step, addresses, addAddressModalShow]);


  const handleCaptchaSuccess = (value) => {
    setIsCaptchaCompleted(true); // Abilita il pulsante "Continua"
  };

  const checkShowCaptcha = () => {
    const shouldShowCaptcha = (step === 3);
    setShowCaptcha(shouldShowCaptcha);
  };

  useEffect(() => {
    checkShowCaptcha();
  }, [step]);

  const renderCaptcha = useCallback(() => {
    if (showCaptcha) {
      return (
        <div className="mt-4">
          <ReCAPTCHA
            sitekey="6LdOd90pAAAAAF32QGhElrkHT8a0V85EwDdkBjnH"
            onChange={handleCaptchaSuccess}
          />
        </div>
      );
    }
    return null;
  }, [showCaptcha]);

  const handleStepIncrement = () => {
    setStep(prevStep => prevStep + 1);
  };
  const renderCheckoutForm = useCallback(() => {
    const service = supplier.services.find((service) => service.service_id === search.service_id);
    const amountToPay = parseFloat(
      ((service.price * service.duration) / 6000) * search.timeslots.length,
    ).toFixed(2);

    return (
      <div>
        <h5>Pagamento con PayPal</h5>
        <PayPalButton 
          history={history} 
          sendReservationRequest={sendReservationRequest} 
          setLoading={setLoading} 
          price={amountToPay} 
          incrementStep={handleStepIncrement} // Passa la funzione per incrementare lo step
        />
      </div>
    );
  }, [step]);
  


//   const renderCheckoutForm = useCallback( () => {
//     const stripePromise = loadStripe('pk_live_51GGROPAnTqj8A5wtNyDz7QPQgMuj4NLLVAIpKUPdWslS9RpPqlLyp95g7YwlGW3kE7sJgiZnXwrtTsKox1X1MyNb00xY04H1AN');
//     const service = supplier.services.find((service) => service.service_id === search.service_id);
//     const price = parseFloat(
//       ((service.price * service.duration) / 6000) * search.timeslots.length,
//     ).toFixed(2);

//     //const price = 0.50; //TEST

//     console.log('--------PRICE:');
//     console.log(price);

//     const options = {
//       mode: 'payment',
//       amount: parseInt(price * 100),
//       currency: 'eur',
//       appearance: {theme: 'stripe'},
//     };
  
//     return (
//       <Elements stripe={stripePromise} options={options}>
//         <StripeCheckout history={history} sendReservationRequest={sendReservationRequest} setLoading={setLoading} price={price}/>
//       </Elements>
//     );
// }, [step]);

  // const renderPaymentMethodSelectionForm = useCallback(() => {
  //   setStep(step + 1); /* NEW */
  //   console.log("STEP");
  //   console.log(step);
  //   return null;

  //   // if (paymentMethods === undefined) {
  //   //   return <Loader />;
  //   // }

  //   // return (
  //   //   <Elements stripe={stripePromise}>
  //   //     <PaymentForm />
  //   //   </Elements>

  //   // );
  // }, [step, addresses, addAddressModalShow]);

  const renderReservationRequestRecap = useCallback(() => {
    const service = supplier.services.find((service) => service.service_id === search.service_id);

    return (
      <div className="reservation-request-recap-wrapper">
        <div className="reservation-request-recap-item">
          <h6>Servizio richiesto da</h6>
          <p>{`${user.data.first_name} ${user.data.last_name}`}</p>
        </div>
        {search.needy_id && (
        <div className="reservation-request-recap-item">
          <h6>Per conto di</h6>
          <p>{user.data.needy.find((n) => n.id === search.needy_id).full_name}</p>
        </div>
        )}
        <div className="reservation-request-recap-item">
          <h6>Servizio richiesto</h6>
          <p>{services.find((service) => service.id === search.service_id).name}</p>
        </div>
        <div className="reservation-request-recap-item">
          <h6>Descrizione servizio richiesto</h6>
          <p>{service.description}</p>
        </div>
        <div className="reservation-request-recap-item">
          <h6>Fornitore</h6>
          <p>{supplier.info.rag_soc}</p>
        </div>
        <div className="reservation-request-recap-item">
          <h6>Quando</h6>
          <p>{formatEventDatetime(search)}</p>
        </div>
        <div className="reservation-request-recap-item">
          <h6>Dove</h6>
          {getFullAddress(search.address)}

          {search.address.coordinates && (
          <SupplierMap
            lat={search.address.coordinates.lat}
            lng={search.address.coordinates.lng}
          />
          )}
        </div>
        {search.notes && (
        <div className="reservation-request-recap-item" style={{ marginTop: 20 }}>
          <h6>Note</h6>
          <p>{search.notes}</p>
        </div>
        )}
        <div className="reservation-request-recap-item" style={{ marginTop: 20 }}>
          <h6>Preventivo costi</h6>
          {`€ ${parseFloat(
            ((service.price * service.duration) / 6000) * search.timeslots.length,
          ).toFixed(2)}`}
        </div>

      </div>
    );
  }, [step, notes, search]);

  const renderHeader = useCallback(() => (
    <div style={{ textAlign: 'center' }}>
      <h5 style={{ textAlign: 'center' }}>Prenotazione</h5>
      {step === 1 && (<p>Aggiungi adesso eventuali note</p>)}
      {step === 2 && (<p>Seleziona l&apos;indirizzo</p>)}
      {step === 3 && (<p>Seleziona l&apos;anziano a cui verrà fornito il servizio</p>)}
      {step === 5 && (<p>Seleziona il metodo di pagamento</p>)}
    </div>
  ), [step]);

  const renderContent = useCallback(() => {
    if (step === 1) return renderNotesForm();
    if (step === 2) return renderAddressSelectionForm();
    if (step === 3) return renderNeedySelection(); 
    if (step === 4) return renderCaptcha(); //ReCaptcha di google
    if (step === 5) return renderCheckoutForm();//renderCheckoutForm();//renderPaymentMethodSelectionForm(); //NEW2
    if (step === 6) return renderReservationRequestRecap();
    //if (step === 6) return renderCheckoutForm(); /* NEW */

    return null;
  }, [step, search, addAddressModalShow, addresses]);

  const renderFooter = useCallback(() => {
    if (step === 1) {
      return (
        <div>
          <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
          <Button
            className="continue-button"
            variant="green"
            onClick={handleContinue}
          >
            Continua
          </Button>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
          <Button
            className="continue-button"
            disabled={selectedAddress === undefined}
            variant="green"
            onClick={() => {
              addSearchOption({
                key: 'address',
                data: selectedAddress,
              });
              setStep(step + 1);
            }}
          >
            Continua
          </Button>
        </div>
      );
    }

    if (step === 3) {
      return null;
      return (
        <div>
          <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
          <Button
            className="continue-button"
            disabled={selectedAddress === undefined}
            variant="green"
            onClick={() => {
              addSearchOption({
                key: 'paymentMethod',
                data: selectedPaymentMethod,
              });
              setStep(step + 1);
            }}
          >
            Continua
          </Button>
        </div>
      );
    }if (step === 4) {
      return (
        <div>
          {/* <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button> */}
          <Button
            className="continue-button"
            variant="green"
            disabled={!isCaptchaCompleted} 
            onClick={() => {
              setStep(step + 1);
            }}
          >
            Continua
          </Button>
        </div>
      );
    }


    if (step === 999) {
      console.log("QUI1")
      //const stripe = useStripe();
      //const elements = useElements();
      console.log("QUI2");
      return (
        <div>
          <Button className="deny-button" variant="red" onClick={() => onClose && onClose()}>Annulla</Button>
          <Button
            className="continue-button"
            variant="green"
            disabled={loading}
            //onClick={createStripeIntent()}
            // onClick={() => {
            //   /* NEW */
            //   console.log('TEST');
            //   const stripe = useStripe();
            //   const elements = useElements();
            //   createStripeIntent(stripe,elements);
            //   //setStep(step + 1); 
            //   //return renderCheckoutForm();


            //   // sendReservationRequest(history);
            //   // setLoading(true);
            //   // history.push('/dashboard/reservation-requests');
            // }}
          >
            <div style={{ width: 140 }}>
              {loading ? <Loader noMargin size={20} /> : 'Paga con stripe'}

            </div>

          </Button>
        </div>
      );
    }

    return null;
  }, [step, notes, search, loading,isCaptchaCompleted]);

  return (
    <>
      <Modal.Header style={{ borderWidth: 0 }} closeButton>
        {renderHeader()}
      </Modal.Header>
      <Modal.Body className="reservation-from-modal-content">
        {renderContent()}
      </Modal.Body>
      <Modal.Footer style={{ borderWidth: 0, marginTop: 20 }}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {renderFooter()}
        </div>
      </Modal.Footer>
    </>
  );

};

export default withRouter(LoggedReservationStepForm);

/*

<Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <CustomInput
              required
              value={search.address ? getFullAddress(search.address) : getFullAddress(addresses[0])}
              id="gender"
              variant="outlined"
              contentEditable={false}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {addresses.map((address) => (
              <Dropdown.Item onSelect={() => {
                addSearchOption({
                  key: 'address',
                  data: {
                    address: address.line1,
                    city: address.city.name,
                    postalCode: address.postal_code,
                    coordinates: {
                      lat: address.geometries.latitude,
                      lng: address.geometries.longitude,
                    },
                  },
                });
              }}
              >
                {getFullAddress(address)}

              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
*/
