import React from 'react';
import TextField from '@material-ui/core/TextField';

import {
  withStyles,
} from '@material-ui/core/styles';

import colors from '../../res/colors';
import './styles.css';

import CustomSelectInput from './CustomSelectInput';
import CustomAddressInput from './CustomAddressInput';

const CustomInputInner = (props) => {
  const {
    id, type, error, value,
  } = props;

  const localProps = {
    ...props,
    value: value || '',
  };

  if (type === 'select') {
    return <CustomSelectInput {...localProps} />;
  }

  if (type === 'address') {
    return <CustomAddressInput {...localProps} />;
  }

  return (
    <div className="custom-input-wrapper">
      <TextField {...localProps} />
      {error !== undefined && <label className="error-label" htmlFor={id}>{error}</label>}
    </div>
  );
};

const CustomInput = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.secondary.normal,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.secondary.normal,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.primary.normal,
      },
      '&:hover fieldset': {
        borderColor: colors.secondary.light,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.secondary.normal,
      },
    },
  },
})(CustomInputInner);

export default CustomInput;
