import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MaxDistance from './MaxDistance';

import { updateMaxDistance } from '../../../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateMaxDistance,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MaxDistance);
