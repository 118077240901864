import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RequestServiceModal from './RequestServiceModal';

import {
  newServiceRequest,
} from '../../store/actions';

const mapStateToProps = (state) => ({
  user: state.user,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  newServiceRequest,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestServiceModal);
