import React, { useEffect } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import './style.css';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getMediaUrl } from '../../libs/utils';

const UserIconTopBar = ({ user, logout, history }) => (
  <div className="user-icon-top-bar">
    <Dropdown>
      <Dropdown.Toggle>
        {user.data && user.data.media && getMediaUrl(user.data.media.profile)
          ? <img alt="User" src={getMediaUrl(user.data.media.profile)} />
          : (
            <AccountCircleIcon style={{
              color: '#E6E6E6', fontSize: 55, padding: 0, margin: -5,
            }}
            >
                filled
            </AccountCircleIcon>
          )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {user.data && (
        <p className="user-full-name">{`${user.data.first_name} ${user.data.last_name}`}</p>
        )}
        <Dropdown.Item onSelect={() => logout(() => {
          history.push('/');
        })}
        >
            Esci
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default withRouter(UserIconTopBar);
