import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './styles.css';

const WhoWeAre = () => (
  <div className="who-we-are-wrapper">
    <Container className="who-we-are">
      <Container container style={{ marginTop: 30, marginBottom: 60 }}>
        <h2>CHI SIAMO</h2>
        <p style={{ marginTop: 20 }}>
          <b>and_dna</b>
          {' '}
è un team di founder e manager collaudati con esperienze
comprovate in iniziative imprenditoriali e profonda conoscenza dei nodi
critici nella rete dei mentor e degli advisor italiani.

        </p>
        <p style={{ marginTop: 20 }}>
          <b>and_dna</b>
          {' '}
accompagna i talenti, start-up, investitori, aziende e leader dell’innovazione con l’obiettivo di trasformare le start-up in progetti sostenibili nel panorama dell’innovazione in Italia e sviluppare le proprie potenzialità nel pano
        </p>
      </Container>
    </Container>
  </div>
);

export default withRouter(WhoWeAre);
