import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addSearchOption } from '../../store/actions';
import NotificationBell from './NotificationBell';

const mapStateToProps = (state) => ({
  notifications: state.notifications,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
  addSearchOption,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationBell);
