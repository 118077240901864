import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';

import PasswordResetForm from '../containers/password-reset/form';
import PasswordReset from '../containers/password-reset/reset';

export default () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <PasswordResetForm />
      </Route>
      <Route path={`${match.path}/:token`}>
        <PasswordReset />
      </Route>
    </Switch>
  );
};
