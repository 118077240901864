import React from 'react';

const SecuritySettings = () => (
  <div className="settings-content-wrapper account-settings-wrapper">
    <h6>Manutenzione</h6>
    <p>Questa sezione non è ancora attiva.</p>
  </div>
);

export default SecuritySettings;
