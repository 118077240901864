/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';

import RegistrationChoice from '../containers/registration/choice';
import RegistrationForm from '../containers/registration/registration-form';
import ConfirmEmail from '../containers/registration/confirm-email';
import AddNeedy from '../containers/registration/carer/add-needy';


export default () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/anziano`} exact>
        <RegistrationForm type="needy" />
      </Route>

      <Route path={`${match.path}/familiare`} exact>
        <RegistrationForm type="carer" />
      </Route>

      <Route path={`${match.path}/fornitore`} exact>
        <RegistrationForm type="supplier" />
      </Route>

      <Route path={`${match.path}/familiare/anziano`}>
        <AddNeedy />
      </Route>

      <Route path={`${match.path}/conferma-email`}>
        <ConfirmEmail />
      </Route>

      <Route path={`${match.path}`}>
        <RegistrationChoice />
      </Route>

    </Switch>
  );
};
