export const FLUSH_SEARCH_CONFIG = 'search/FLUSH';
export const ADD_SEARCH_OPTION = 'search/ADD_OPTION';
export const PERFORM_SEARCH = 'search/PERFORM';

export const flushSearchConfig = () => ({
  type: FLUSH_SEARCH_CONFIG,
});

export const addSearchOption = (payload) => ({
  type: ADD_SEARCH_OPTION,
  payload,
});

export const performSearch = () => ({
  type: PERFORM_SEARCH,
});
